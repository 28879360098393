import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import BTNBG from "../assets/gif/BTNBG.gif";
import BPOS from "../assets/images/BPOS.png";
import config from "../config/config.json";

const { ethers } = require("ethers");

const NavbarContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  height: 80px;
  width: 100%;
  color: white;
  top: 0;
`;
const LogoImage = styled.img`
  height: 80px;
  width: auto;
`;
const NavLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 30px;
`;

const Linker = styled(RouterLink)`
  text-decoration: none;
  color: white;
`;

const ConnectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50px;
  background-image: url(${BTNBG});
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 150px;
  padding: 10px;
  color: white;
`;

const Utility = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

const Navbar = () => {
  // when the page is scrolling, the navbar will be fixed to the top
  const [isScrolling, setIsScrolling] = useState(false);
  window.addEventListener("scroll", () => {
    if (window.scrollY > 0) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  });

  const [isConnected, setIsConnected] = useState(false);
  const [defaultAccount, setDefaultAccount] = useState(null);

  const connect = () => {
    if (window.ethereum !== undefined) {
      let chain = config.chainId.toString();
      if (window.ethereum.networkVersion === chain) {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((account) => {
            setIsConnected(true);
            accountChangedHandler(account[0]);
            console.log(defaultAccount);
          });
      } else {
        alert("Please connect to the correct network");
      }
    } else {
      setIsConnected(false);
    }
  };

  const accountChangedHandler = (account) => {
    setDefaultAccount(account);
  };

  const utility = () => {
    window.open("https://www.boredpunksociety.com/utilities");
  };

  return (
    <NavbarContainer
      style={{
        backgroundColor: isScrolling ? "black" : "transparent",
      }}
    >
      <LogoImage src={BPOS} />
      <NavLinks>
        <Linker to="/">
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Home
          </p>
        </Linker>
        <Utility onClick={utility}>
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Benefits
          </p>
        </Utility>
        <Linker to="/updates">
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Updates
          </p>
        </Linker>
        <Linker to="/myboredpunk">
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            My Bored Punk
          </p>
        </Linker>
        <Linker to="/leaderboards">
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Leaderboards
          </p>
        </Linker>
        {/* <Linker to="/forum">
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Forum
          </p>
        </Linker>
        <Linker to="/members">
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Members
          </p>
        </Linker> */}
      </NavLinks>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "5%",
        }}
      ></div>
    </NavbarContainer>
  );
};

export default Navbar;
