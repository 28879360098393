import React from "react";
import { AiFillHeart } from "react-icons/ai";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import { BsBookmarkFill } from "react-icons/bs";

import styled from "styled-components";

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 20px 20px 20px;
  padding: 20px;
  border-radius: 20px;
  gap: 10px;
`;

const Splitter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`;

const ProfileImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

const Username = styled.h2`
  color: #35c78a;
  font-size: 18px;
  font-weight: 500;
`;

const StyledBookmark = styled(BsBookmarkFill)`
  font-size: 20px;
  color: #c77dbb;
`;

const StyledHeart = styled(AiFillHeart)`
  font-size: 20px;
  color: #fff;
`;

const StyledUpvotedUp = styled(BiCaretUp)`
  font-size: 40px;
  color: #31b07c;
`;

const StyledUpvotedDown = styled(BiCaretDown)`
  font-size: 40px;
  color: #d15455;
`;

const StyledUp = styled(BiCaretUp)`
  font-size: 40px;
  color: #fff;
`;

const StyledDown = styled(BiCaretDown)`
  font-size: 40px;
  color: #fff;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  background-color: #373038;
  border-radius: 20px;
  padding: 20px;
`;

const CommentTitle = styled.h2`
  width: 100%;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
`;

const CommentDescription = styled.p`
  color: #fff;
  font-size: 14px;
`;

const StyledTags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(199, 125, 187, 0.2);
  padding: 5px 10px;
  color: #fff;
`;

const ReplyContainer = styled.div`
  width: 100%;
  border-left: 2px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 20px;
`;

const ReplyTextContainer = styled.div`
  margin-left: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Position = styled.h2`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: rgba(199, 125, 187, 0.5);
  border-radius: 50px;
  padding: 5px 10px;
`;

const ReplyComponent = () => {
  return (
    <Splitter
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Splitter>
          <ProfileImage src="https://i.imgur.com/0y0t0Xy.png" />
          <Splitter>
            <div>
              <Username>John Doe</Username>
              <p>2 hours ago</p>
            </div>
            <Position>CEO of Playdex</Position>
          </Splitter>
        </Splitter>
        <ReplyTextContainer>
          <CommentDescription>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
          </CommentDescription>
        </ReplyTextContainer>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <StyledUpvotedUp />
        <h2>7</h2>
        <StyledDown />
      </div>
    </Splitter>
  );
};

const CommentComponent = () => {
  return (
    <CommentContainer>
      <Splitter
        style={{
          width: "100%",
        }}
      >
        <ProfileImage src="https://i.imgur.com/0y0t0Xy.png" />
        <Splitter
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Username>John Doe</Username>
            <p>2 hours ago</p>
          </div>
          <Splitter>
            <StyledHeart />
            <StyledBookmark />
          </Splitter>
        </Splitter>
      </Splitter>
      <Splitter>
        <TextContainer>
          <CommentTitle>IM SO ANGRY GRRRRRR</CommentTitle>
          <CommentDescription>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            tincidunt, nunc vel tincidunt luctus, nunc nisl aliquam nisl, nec
            tincidunt nisl nunc vel nunc. Sed tincidunt, nunc vel tincidunt
            luctus, nunc nisl aliquam nisl, nec tincidunt nisl nunc vel nunc.
          </CommentDescription>
          <Splitter>
            <StyledTags>#angry</StyledTags>
            <StyledTags>#mad</StyledTags>
            <StyledTags>#grrrrrr</StyledTags>
          </Splitter>
        </TextContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <StyledUpvotedUp />
          <h2>7</h2>
          <StyledDown />
        </div>
      </Splitter>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          padding: "0 80px",
        }}
      >
        <ReplyContainer>
          <ReplyComponent />
          <ReplyComponent />
        </ReplyContainer>
      </div>
    </CommentContainer>
  );
};

export default CommentComponent;
