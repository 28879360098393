import React from "react";
import { AiFillLike, AiFillPushpin, AiOutlineEye } from "react-icons/ai";
import { BiMessageAlt, BiUpload } from "react-icons/bi";
import { BsBookmarkFill } from "react-icons/bs";
import { HiUser } from "react-icons/hi";
import { RiUserShared2Fill } from "react-icons/ri";
import styled from "styled-components";
import BTNBG from "../assets/gif/BTNBG.gif";

const LeftCompContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  padding: 20px;
  width: 20vw;
  gap: 20px;
  background-color: rgba(0, 0, 0, 0.6);
`;

const ProfileImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

const ImageContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px 20px 5px 5px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid white;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
`;

const Splitter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const STyledFillLike = styled(AiFillLike)`
  font-size: 20px;
`;

const StyledUser = styled(HiUser)`
  font-size: 20px;
`;

const StyledEye = styled(AiOutlineEye)`
  font-size: 20px;
  color: lightgray;
`;

const StyledUserVisits = styled(RiUserShared2Fill)`
  font-size: 20px;
  color: lightgray;
`;

const StyledPin = styled(AiFillPushpin)`
  font-size: 30px;
  color: #c77dbb;
`;

const StyledBookmark = styled(BsBookmarkFill)`
  font-size: 20px;
  color: #c77dbb;
`;

const StyledMessage = styled(BiMessageAlt)`
  font-size: 20px;
  color: white;
`;

const StyledUpload = styled(BiUpload)`
  font-size: 20px;
  color: white;
`;

const MiniImage = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const MiniImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledTags = styled.p`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px 15px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  font-size: 14px;
`;

const AnnouncementContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const AnnouncementHeader = styled.div`
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5px 20px 5px 20px;
  background-image: url(${BTNBG});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px 0px 0px 20px;
  transform: translate(20px, 0px);
`;

const AnnouncementImage = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 20px;
  background-image: url(https://i.imgur.com/4Q4Q4Q4.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const AnnouncementTitle = styled.h1`
  color: #fff;
  max-width: 400px;
  font-size: 30px;
`;

const LeftCompany = () => {
  return (
    <LeftCompContainer>
      <ImageContainer>
        <ProfileImage src="https://i.imgur.com/4Q4Q4Q4.png" />
        <p>its_melonbabe</p>
      </ImageContainer>

      <StatsContainer>
        <Splitter
          style={{
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <Splitter
            style={{
              color: "#31b07c",
            }}
          >
            <STyledFillLike />
            <b>1.2k</b> liked this
          </Splitter>
          <MiniImageContainer>
            <MiniImage src="https://i.imgur.com/4Q4Q4Q4.png" />
            <MiniImage src="https://i.imgur.com/4Q4Q4Q4.png" />
            <MiniImage src="https://i.imgur.com/4Q4Q4Q4.png" />
          </MiniImageContainer>
        </Splitter>
        <Splitter
          style={{
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <Splitter
            style={{
              color: "#31b07c",
            }}
          >
            <StyledUser />
            <b>1.9k</b> followers
          </Splitter>
          <MiniImageContainer>
            <MiniImage src="https://i.imgur.com/4Q4Q4Q4.png" />
            <MiniImage src="https://i.imgur.com/4Q4Q4Q4.png" />
          </MiniImageContainer>
        </Splitter>

        <StyledTags>View Mutuals</StyledTags>
        <Splitter
          style={{
            width: "100%",
            justifyContent: "space-evenly",
            gap: "0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              borderRight: "1px solid white",
            }}
          >
            <p>Viewers</p>
            <Splitter
              style={{
                color: "lightgray",
              }}
            >
              <StyledEye /> <b>892k</b>
            </Splitter>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p>Visits</p>
            <Splitter
              style={{
                color: "lightgray",
              }}
            >
              <StyledUserVisits /> <b>1.5M</b>
            </Splitter>
          </div>
        </Splitter>
      </StatsContainer>
      <Splitter
        style={{
          width: "100%",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <StyledPin />
        <AnnouncementHeader>Announcement</AnnouncementHeader>
      </Splitter>
      <AnnouncementContainer>
        <AnnouncementImage src="https://i.imgur.com/4Q4Q4Q4.png" />
        <Splitter
          style={{
            width: "100%",
          }}
        >
          <StyledTags>11/11/2022</StyledTags>
        </Splitter>
        <div>
          <AnnouncementTitle>
            Playdex Cup: Axie Origin Tournament is here!
          </AnnouncementTitle>
        </div>
        <Splitter
          style={{
            width: "100%",
            color: "lightgray",
            alignItems: "center",
            justifyContent: "space-evenly",
            borderTop: "1px solid white",
            padding: "20px",
          }}
        >
          <Splitter>
            <StyledEye /> 892k
          </Splitter>
          <Splitter>
            <StyledMessage /> 892k
          </Splitter>
          <Splitter>
            <StyledBookmark />
            <StyledUpload />
          </Splitter>
        </Splitter>
      </AnnouncementContainer>
    </LeftCompContainer>
  );
};

export default LeftCompany;
