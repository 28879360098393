import React, { useState } from "react";
import styled from "styled-components";
import CommentComponent from "./CommentComponent";
import CommentForm from "./CommentForm";
import CompanyUpdates from "./CompanyUpdates";

const CompanyForumContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
`;

const Splitter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const StyledTags = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px 15px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  font-size: 14px;
`;

const FirstDiv = styled.div``;
const MiddleDiv = styled.div``;

const CompanyForum = () => {
  const [isDiscussion, setIsDiscussion] = useState(false);
  const [isUpdates, setIsUpdates] = useState(true);

  const handleDiscussion = () => {
    console.log(isUpdates);
    console.log(isDiscussion);
    if (isUpdates) {
      setIsUpdates(false);
    }
    setIsDiscussion(true);
  };

  const handleUpdates = () => {
    console.log(isUpdates);
    console.log(isDiscussion);
    if (isDiscussion) {
      setIsDiscussion(false);
    }
    setIsUpdates(true);
  };

  return (
    <CompanyForumContainer>
      <Splitter
        style={{
          justifyContent: "space-between",
          width: "100%",
          padding: "0 20px",
        }}
      >
        <nav className="left">
          <FirstDiv
            className={`tab ${isUpdates ? "selected" : ""}`}
            onClick={handleUpdates}
          >
            Updates
          </FirstDiv>
          <MiddleDiv
            className={`tab ${isDiscussion ? "selected" : ""}`}
            onClick={handleDiscussion}
          >
            Discussions
          </MiddleDiv>
        </nav>

        <Splitter
          style={{
            gap: "20px",
          }}
        >
          <Splitter
            style={{
              gap: "10px",
            }}
          >
            <StyledTags>Recent</StyledTags>
            <StyledTags>Top</StyledTags>
            <StyledTags>Trending</StyledTags>
          </Splitter>
          <StyledTags>This Year</StyledTags>
        </Splitter>
      </Splitter>
      <div
        style={{
          backgroundColor: "#302931",
          width: "100%",
          borderRadius: "10px",
        }}
      >
        <CommentForm />
        {isDiscussion ? <CommentComponent /> : <CompanyUpdates />}
      </div>
    </CompanyForumContainer>
  );
};

export default CompanyForum;
