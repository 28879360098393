import React from "react";
import LeaderboardCard from "./LeaderboardCard";

const LeaderboardsList = ({ list, indexOfFirstPost, indexOfLastPost }) => {
  const newLIst = list.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        maxWidth: "100%",
      }}
    >
      {newLIst.map((item, index) => {
        if (Number(item.lockedTokens) > 0) {
          return (
            <LeaderboardCard
              username={item.username}
              score={item.score}
              lockedTokens={item.lockedTokens}
              index={indexOfFirstPost + index}
              status={item.status}
              likes={item.likes.length}
              tokenId={item.tokenId}
              favPFP={item.favPFP}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default LeaderboardsList;
