import "./App.css";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import BG from "./assets/images/BG2.png";
import CompanyProfile from "./pages/CompanyProfile";
import Discounts from "./pages/Discounts";
import Footer from "./pages/Footer";
import Forum from "./pages/Forum";
import Home from "./pages/Home";
import Locking from "./pages/Locking";
import Members from "./pages/Members";
import Navbar from "./components/Navbar";
import Profile from "./pages/Profile";
import Updates from "./pages/Updates";
import User from "./pages/User";

function App() {
  return (
    <>
      <Router>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "max-content",
            width: "100%",
            backgroundImage: `url(${BG})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflow: "hidden",
          }}
        >
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/bored/:user" component={User} />
            <Route path="/leaderboards" component={Profile} />
            <Route path="/updates" component={Updates} />
            <Route path="/myboredpunk" component={Locking} />
            <Route path="/forum" component={Forum} />
            <Route path="/members" component={Members} />
            <Route path="/partner" component={CompanyProfile} />
          </Switch>
        </div>
      </Router>
    </>
  );
}

export default App;
