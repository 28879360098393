import React from "react";
import styled from "styled-components";
import scarlet2 from "../assets/images/M3A.png";

import SB1 from "../assets/images/SB1.jpg";
import scarlet1 from "../assets/images/scarlet1.jpg";

import beyond from "../assets/images/beyond.png";
import beyondLogo from "../assets/images/beyondlogo.png"

import RedeemCard from "./RedeemCard";

const RedeemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  gap: 20px;
`;

const RedeemingComponent = ({ toggle, toggle2 }) => {
  return (
    <RedeemContainer>
      <h1>Redeem</h1>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        <RedeemCard
          toggle={toggle}
          title="[Whitelist]Larry Alcala's Slice of Life NFT"
          description="For Scarletbox’s second drop, it pays Tribute to the beloved Cartoonist
        and National Artist Larry Alcala with a witty and fun PFP (Profile
        Picture) Collection..."
          company="Scarletbox"
          src={SB1}
          displayImg={scarlet1}
          isExpired={true}
        />

        <RedeemCard
          toggle={toggle2}
          title="[50% OFF] M3R S3A: Web3 by the Sea!"
          description="Party with builders, VCs, entrepreneurs, gamers, degens, crypto OGs, and the bored community on Nov. 18 - 20 at Flotsam & Jetsam Hostel, La Union!"
          company="M3RS3A"
          src={scarlet2}
          displayImg={scarlet2}
          isExpired={true}
        />

        <RedeemCard
          toggle={toggle}
          title="BPoS X BeyondClub"
          description="beyondClub is joining the web3 Boot Camp in the Philippines this April 27-29 for the first time. And we partnered with Bored Punks of Society to onboard more brands and creators in the country through NFTs."
          company="beyondClub"
          src={beyond}
          displayImg={beyondLogo}
          isClickable={true}
        />
      </div>
    </RedeemContainer>
  );
};

export default RedeemingComponent;
