import {
  FaCommentAlt,
  FaDiscord,
  FaFacebook,
  FaHeart,
  FaInstagram,
  FaRegCommentAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { FiCheckSquare, FiEdit3, FiLoader, FiSquare } from "react-icons/fi";
import React, { useEffect, useState } from "react";

import BTNBG from "../assets/gif/BTNBG.gif";
import { BiTrophy } from "react-icons/bi";
import { BsSafe } from "react-icons/bs";
import Elite from "../assets/images/Elite.png";
import { FiMoreHorizontal } from "react-icons/fi";
import Holder from "../assets/images/Holder.png";
import MTBG from "../assets/gif/MTBG.gif";
import Monarch from "../assets/images/Monarch.png";
import None from "../assets/images/None.png";
import Pioneer from "../assets/images/Pioneer.png";
import Premier from "../assets/images/Premier.png";
import Tippy from "@tippyjs/react";
import axios from "axios";
import config from "../config/config.json";
import jasbo from "../assets/images/jasbo.png";
import locking from "../abi/locking.json";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

const { ethers } = require("ethers");

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  padding: 50px 300px;
  gap: 20px;
  @media (max-width: 1440px) {
    padding: 50px 150px;
  }
  @media (max-width: 1024px) {
    padding: 20px 50px;
    gap: 20px;
  }

  @media (max-width: 620px) {
    padding: 20px;
  }
  @media (max-width: 425px) {
    gap: 10px;
  }
`;

const NFTCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  overflow: hidden;
  transition: ease-in-out 1s;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    width: 45%;
  }
`;

const RoundButton = styled.button`
  background-image: url(${BTNBG});
  background-repeat: no-repeat;
  background-size: cover;
  width: 200px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50px;
  color: white;
`;

const FirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 20px;
`;

const UsernameText = styled.h1`
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 15px;
  }
  @media (max-width: 680px) {
    font-size: 12px;
  }
  @media (max-width: 620px) {
    font-size: 10px;
  }
  @media (max-width: 375px) {
    font-size: 5px;
  }
`;

const UsernameText2 = styled.h1`
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
`;

const SubtitleText = styled.h2`
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 15px;
  }
`;

const ParagraphText = styled.p`
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 15px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const HideOnMobile = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 1024px) {
    gap: 10px;
  }
  @media (max-width: 680px) {
    display: none;
  }
`;

const HideOnMobile2 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 1024px) {
    gap: 10px;
  }
  @media (max-width: 620px) {
  }
`;

const FirstBottomContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
  height: 150px;
  border-radius: 0px 0px 20px 20px;
  @media (max-width: 1024px) {
    height: 100px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    height: max-content;
  }
  @media (max-width: 425px) {
    gap: 10px;
  }
`;

const ProfileImage = styled.img`
  position: inherit;
  width: 25%;
  transform: translateY(-35%);
  border-radius: 10px;
  @media (max-width: 768px) {
    transform: translateY(-100%);
  }
`;

const ProfileImageWrapper = styled.div`
  display: none;
  height: 0px;
  width: 100%;
  @media (max-width: 768px) {
    display: block;
  }
`;

const ProfileImage3 = styled(ProfileImage)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const TierStats = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: center;
  align-items: center;
`;
const TierData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  min-height: 100px;
`;

const Splitter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media (max-width: 1024px) {
    gap: 10px;
  }
  @media (max-width: 680px) {
    gap: 5px;
  }
`;

const BannerImage = styled.img`
  width: 100%;
  border-radius: 20px 20px 0 0;
`;

const SecondContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  border-radius: 20px;
  padding: 20px;
  border: 2px solid white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
`;

const ThirdContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.6);
  @media (max-width: 425px) {
    gap: 10px;
    padding: 10px;
  }
`;

const BoredNFTContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media (max-width: 425px) {
    gap: 10px;
  }
`;

const MottoContainer = styled.div`
  background-image: url(${MTBG});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
`;

const StyledEditIcon = styled(FiEdit3)`
  cursor: pointer;
  font-size: 30px;

  color: white;
  @media (max-width: 768px) {
    font-size: 10px;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
  }
  @media (max-width: 620px) {
    font-size: 20px;
  }

  :hover {
    scale: 1.2;
    transition: 0.2s;
  }
`;

const StyledFacebookIcon = styled(FaFacebook)`
  font-size: 30px;
  color: white;
  @media (max-width: 768px) {
    font-size: 10px;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
  }
  @media (max-width: 620px) {
    font-size: 20px;
  }
`;

const StyledTwitterIcon = styled(FaTwitter)`
  font-size: 30px;
  color: white;
  @media (max-width: 768px) {
    font-size: 10px;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
  }
  @media (max-width: 620px) {
    font-size: 20px;
  }
`;

const StyledInstagramIcon = styled(FaInstagram)`
  font-size: 30px;
  color: white;
  @media (max-width: 768px) {
    font-size: 10px;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
  }
  @media (max-width: 620px) {
    font-size: 20px;
  }
`;

const StyledDiscordIcon = styled(FaDiscord)`
  font-size: 30px;
  color: white;
  @media (max-width: 768px) {
    font-size: 10px;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
  }
  @media (max-width: 620px) {
    font-size: 20px;
  }
`;

const StyledYoutubeIcon = styled(FaYoutube)`
  font-size: 30px;
  color: white;
  @media (max-width: 768px) {
    font-size: 10px;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
  }
  @media (max-width: 620px) {
    font-size: 20px;
  }
`;

const StyledTrophyIcon = styled(BiTrophy)`
  font-size: 30px;
  color: white;
  @media (max-width: 768px) {
    font-size: 10px;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
  }
  @media (max-width: 620px) {
    font-size: 20px;
  }
`;

const StyledSafeIcon = styled(BsSafe)`
  font-size: 30px;
  color: white;
  @media (max-width: 768px) {
    font-size: 10px;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
  }
  @media (max-width: 620px) {
    font-size: 20px;
  }
`;

const StyledHeartIcon = styled(FaHeart)`
  cursor: pointer;
  font-size: 30px;
  color: #fa383e;
  @media (max-width: 768px) {
    font-size: 10px;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
  }
  @media (max-width: 620px) {
    font-size: 20px;
  }
  :hover {
    scale: 1.2;
    transition: 0.2s;
  }
`;

const StyledInput = styled.input`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  color: white;
  background-color: transparent;
  border-bottom: 1px solid white;
  :focus {
    outline: none;
  }
  ::-webkit-input-placeholder {
    color: white;
  }
  @media (max-width: 1024px) {
    font-size: 15px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const StyledInput2 = styled.input`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  color: white;
  background-color: transparent;
  border: 1px solid white;
  width: 100%;
  border-radius: 10px;
  height: 150px;

  :focus {
    outline: none;
  }
  ::-webkit-input-placeholder {
    color: white;
  }
  @media (max-width: 1024px) {
    font-size: 15px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const StyledCheckbox = styled(FiCheckSquare)`
  :hover {
    cursor: pointer;
  }
`;

const StyledSquare = styled(FiSquare)`
  :hover {
    cursor: pointer;
  }
`;

const Splitter2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const BubbleContainer = styled(ParagraphText)`
  font-weight: 500;
  width: max-content;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  outline: 2px solid grey;
  :hover {
    cursor: pointer;
    color: black;
    background-color: white;
    transition: 0.2s;
    outline: none;
  }
`;

const ProfileImage2 = styled.img`
  width: 7%;
  border-radius: 50%;
`;

const StyledMoreIcon = styled(FiMoreHorizontal)`
  font-size: 30px;
  color: white;
  @media (max-width: 768px) {
    font-size: 10px;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
  }
  @media (max-width: 620px) {
    font-size: 20px;
  }
`;

const StyledCommentIcon = styled(FaCommentAlt)`
  cursor: pointer;
  font-size: 30px;
  color: white;
  @media (max-width: 768px) {
    font-size: 10px;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
  }
  @media (max-width: 620px) {
    font-size: 20px;
  }
`;

const StyledInput3 = styled(StyledInput2)`
  height: 100%;
`;

const RowContainer = styled.div`
  display: grid;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  grid-template-areas:
    "one four"
    "one three"
    "two five"
    "two five"
    "two five";
  @media (max-width: 1024px) {
    grid-template-areas:
      "one one four four four"
      "one one four four four"
      "one one three three three"
      "two two two five five"
      "two two two five five"
      "two two two five five";
  }
  @media (max-width: 680px) {
    grid-template-areas:
      "four four four four"
      "one one one one"
      "three three three three"
      "two two five five"
      "two two five five"
      "two two five five";
  }
  @media (max-width: 425px) {
    grid-template-areas:
      "four"
      "one"
      "three"
      "two"
      "five";
  }
`;

const FirstRow = styled.div`
  grid-area: one;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  gap: 20px;
  max-height: 600px;
`;

const SecondRow = styled.div`
  grid-area: two;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
  gap: 20px;
  max-height: 700px;
  @media (max-width: 425px) {
    grid-area: five;
  }
`;

const ThirdRow = styled.div`
  grid-area: three;
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

const FourthRow = styled(SecondContainer)`
  grid-area: four;
`;

const FifthRow = styled.div`
  grid-area: five;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  gap: 20px;
  border-radius: 10px;
  padding: 10px;
  overflow: auto;
  max-height: 700px;
  @media (max-width: 425px) {
    grid-area: two;
  }
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  max-width: 45%;
  height: max-content;
  border-radius: 10px;
  border: 1px solid white;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const NFTContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 0;
  overflow: auto;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    justify-content: center;
    gap: 10px;
    overflow: auto;
  }
`;

const User = (props) => {
  const CommentCard = ({ comment, user, pfp, tokenId }) => {
    console.log(pfp);
    const cleanedId = (uncleaned) => {
      if (pfp.includes("pioneer")) {
        let num = pfp.replace("pioneer:", "");
        // remove spaces
        num = num.replace(/\s/g, "");
        return src3(num);
      } else if (pfp.includes("bored")) {
        let num = pfp.replace("bored:", "");
        num = num.replace(/\s/g, "");
        return src2(num);
      }
    };
    return (
      <CommentContainer>
        <Splitter2>
          <ProfileImage2
            style={{
              width: "14%",
            }}
            src={cleanedId(pfp)}
          />
          <Splitter2
            style={{
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <a
              href={`https://www.boredpunksociety.app/bored/${Number(tokenId)}`}
            >
              <SubtitleText
                style={{
                  fontSize: "15px",
                }}
              >
                {user}.bored
              </SubtitleText>
            </a>
          </Splitter2>
        </Splitter2>

        <ParagraphText
          style={{
            textAlign: "left",
          }}
        >
          {comment}
        </ParagraphText>
      </CommentContainer>
    );
  };

  const NFTCard = ({ tokenId }) => {
    const suffix = tokenId > 100 && tokenId <= 115 ? ".gif" : ".png";

    return (
      <NFTCardContainer
        className="nftcard"
        style={{
          backgroundImage: `url(https://bpos-db.herokuapp.com/pioneer/${Number(
            tokenId
          )}${suffix})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      >
        <img
          src={`https://bpos-db.herokuapp.com/pioneer/${tokenId}${suffix}`}
          alt="NFT"
          style={{
            width: "100%",
            visibility: "hidden",
            overflow: "hidden",
          }}
        />
        <div
          style={{
            height: "0px",
            width: "100%",
          }}
        >
          <UsernameText
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
            className="nftcardtext"
          >
            #{tokenId}
          </UsernameText>
        </div>
      </NFTCardContainer>
    );
  };

  const NFTCard2 = ({ tokenId }) => {
    return (
      <NFTCardContainer
        className="nftcard"
        style={{
          backgroundImage: `url(https://media.raritysniper.com/bored-punks-of-society/${tokenId}-600.webp?cacheId=3)`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      >
        <img
          src={`https://media.raritysniper.com/bored-punks-of-society/${tokenId}-600.webp?cacheId=3`}
          alt="NFT"
          style={{
            width: "100%",
            visibility: "hidden",
          }}
        />
        <div
          style={{
            height: "0px",
            width: "100%",
          }}
        >
          <UsernameText
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
            className="nftcardtext"
          >
            #{tokenId}
          </UsernameText>
        </div>
      </NFTCardContainer>
    );
  };

  const NFTCard3 = ({ tokenId }) => {
    return (
      <NFTCardContainer
        style={{
          backgroundImage: `url(https://media.raritysniper.com/bored-punks-of-society/${tokenId}-600.webp?cacheId=3)`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          borderRadius: "10px",
        }}
        onClick={() => toggleSelectBored(tokenId)}
      >
        <img
          src={`https://media.raritysniper.com/bored-punks-of-society/${tokenId}-600.webp?cacheId=3`}
          alt="NFT"
          style={{
            width: "100%",
            visibility: "hidden",
            borderRadius: "10px",
          }}
        />

        <div
          style={{
            height: "0px",
            width: "100%",
          }}
        >
          <Splitter
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              width: "100%",
              transform: "translateY(-100%)",
              justifyContent: "space-between",
              padding: "0px 10px",
            }}
          >
            <UsernameText>#{tokenId}</UsernameText>
            {selectedNFTs.find((item) => item.bored === tokenId) !==
            undefined ? (
              <FiCheckSquare color="white" />
            ) : (
              <FiSquare color="white" />
            )}
          </Splitter>
        </div>
      </NFTCardContainer>
    );
  };

  const NFTCard4 = ({ tokenId }) => {
    const suffix = tokenId > 100 && tokenId <= 115 ? ".gif" : ".png";
    return (
      <NFTCardContainer
        style={{
          backgroundImage: `url(https://bpos-db.herokuapp.com/pioneer/${tokenId}${suffix})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          borderRadius: "10px",
        }}
        onClick={() => toggleSelectPioneer(tokenId)}
      >
        <img
          src={`https://bpos-db.herokuapp.com/pioneer/${tokenId}${suffix}`}
          alt="NFT"
          style={{
            width: "100%",
            visibility: "hidden",
            borderRadius: "10px",
          }}
        />

        <div
          style={{
            height: "0px",
            width: "100%",
          }}
        >
          <Splitter
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              width: "100%",
              transform: "translateY(-100%)",
              justifyContent: "space-between",
              padding: "0px 10px",
            }}
          >
            <UsernameText>#{tokenId}</UsernameText>
            {selectedNFTs.find((item) => item.pioneer === tokenId) !==
            undefined ? (
              <FiCheckSquare color="white" />
            ) : (
              <FiSquare color="white" />
            )}
          </Splitter>
        </div>
      </NFTCardContainer>
    );
  };

  useEffect(() => {
    if (window.ethereum !== undefined) {
      updateEthers();
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);
  const [expand, setExpand] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [selectedNFTs, setSelectedNFTs] = useState([]);
  const [ownerID, setOwnerID] = useState(null);
  const [comment, setComment] = useState("");

  const [lockingContract, setLockingContract] = useState(null);
  const [lockingProvider, setLockingProvider] = useState(null);
  const [lockingSigner, setLockingSigner] = useState(null);
  const [formData, setFormData] = useState({
    twitter: "",
    youtube: "",
    facebook: "",
    instagram: "",
    description: "",
    skills: [],
    work: "",
  });

  const { twitter, youtube, facebook, instagram, description, skills, work } =
    formData;

  const name = props.match.params.user;

  const isNumber = /^\d+$/.test(name);

  const connect = async () => {
    setIsLoading(true);
    if (window.ethereum !== undefined) {
      let chain = config.chainId.toString();
      if (window.ethereum.networkVersion === chain) {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((account) => {
            signMessage().then(() => {
              checkOwnership(ownerID, account[0]).then((result) => {
                if (result) {
                  setIsConnected(true);
                  setIsLoading(false);
                } else {
                  alert("You do not have permimssion to edit this profile");
                  setIsEditing(false);
                  setIsLoading(false);
                }
              });
            });
          });
      } else {
        alert("Please connect to the correct network");
      }
    } else {
      setIsConnected(false);
    }
  };

  const signMessage = async () => {
    const message = "Please sign this message to prove ownership of this NFT";

    await lockingSigner.signMessage(message);
  };

  const checkOwnership = async (id, owner) => {
    let result = false;
    const tempowner = await lockingContract.walletOfOwner(owner).then((res) => {
      return res.toString();
    });

    if (String(tempowner) === String(id)) {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  const updateEthers = async () => {
    let tempLockingProvider = new ethers.providers.Web3Provider(
      window.ethereum
    );
    let tempLockingSigner = tempLockingProvider.getSigner();

    let lockingContract = new ethers.Contract(
      config.lockingAddress,
      locking,
      tempLockingSigner
    );

    setLockingContract(lockingContract);
    setLockingSigner(tempLockingSigner);
    setLockingProvider(tempLockingProvider);
  };

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "skills") {
      const skills = e.target.value.split(",");
      setFormData({
        ...formData,
        skills,
      });
      console.log(skills);
    }

    console.log(formData);
  };

  const src = () => {
    const status = user.status;
    if (status.includes("Pioneer")) {
      return Pioneer;
    }
    if (status === "Holder") {
      return Holder;
    }
    if (status === "Premier") {
      return Premier;
    }
    if (status === "Elite") {
      return Elite;
    }
    if (status === "Monarch") {
      return Monarch;
    }
    if (status === "None") {
      return None;
    }
  };

  const src2 = (tokenId) => {
    if (tokenId === 0) {
      return `https://bpos-db.herokuapp.com/pioneer/${tokenId}.png`;
    } else {
      return `https://media.raritysniper.com/bored-punks-of-society/${tokenId}-600.webp?cacheId=3`;
    }
  };

  const src3 = (tokenId) => {
    const suffix = tokenId > 100 && tokenId <= 115 ? ".gif" : ".png";

    if (tokenId === 0) {
      return `https://bpos-db.herokuapp.com/pioneer/${tokenId}.png`;
    } else {
      return `https://bpos-db.herokuapp.com/pioneer/${tokenId}${suffix}`;
    }
  };

  const getInformation = async (account) => {
    const proxyurl = config.PROXY_URL;
    const url = config.UPDATE;
    let walletBalance = await lockingContract
      .walletOfOwner(account)
      .then((res) => {
        return res.toString();
      });
    // find the token id of the wallet in leaderboard
    const filtered = leaderboard
      .filter((item) => item.tokenId === Number(walletBalance))
      .map((item) => {
        return {
          user: item.username,
          pfp: item.favPFP,
          comment: comment,
          tokenId: item.tokenId,
        };
      });

    const message = "Please sign this message to comment on this users profile";
    const tempComments = user.comments;

    tempComments.push(...filtered);
    console.log(tempComments);

    await lockingSigner.signMessage(message).then((res) => {
      axios
        .patch(proxyurl + url + user._id, {
          comments: tempComments,
        })
        .then((res) => {
          if (res.data === "Post updated!") {
            alert("Comment added");
            window.location.reload();
          }
        });
    });
  };

  const submitLike = async (account) => {
    const proxyurl = config.PROXY_URL;
    const url = config.UPDATE;
    let walletBalance = await lockingContract
      .walletOfOwner(account)
      .then((res) => {
        return res.toString();
      });
    // find the token id of the wallet in leaderboard
    const filtered = leaderboard
      .filter((item) => item.tokenId === Number(walletBalance))
      .map((item) => {
        return {
          user: item.username,
          pfp: item.favPFP,
          comment: comment,
          tokenId: item.tokenId,
        };
      });

    const message = "Please sign this message to like this users profile";

    const tempLikes = user.likes;

    const result = tempLikes.find((element) => element === filtered[0].user);

    // the user cannot like their own profile
    if (filtered[0].user === user.username) {
      alert("You cannot like your own profile");
      window.location.reload();
    } else {
      if (result === undefined) {
        await lockingSigner.signMessage(message).then((res) => {
          axios
            .patch(proxyurl + url + user._id, {
              likes: filtered[0].user,
            })
            .then((res) => {
              if (res.data === "Post updated!") {
                alert("Comment added");
                window.location.reload();
              }
            });
        });
      } else {
        alert("You have already liked this profile");
        window.location.reload();
      }
    }
  };

  const getData = async () => {
    const tempLeaderboard = config.LEADERBOARD;

    const url = config.URL;
    const proxyurl = config.PROXY_URL;
    setIsLoading(true);
    await axios.get(proxyurl + tempLeaderboard).then((res) => {
      const data = res.data;
      const data2 = res.data;
      setLeaderboard(data);
      const pioneer = [];
      const bored = [];

      const tempTokenId = () => {
        if (isNumber) {
          return data.filter((item) => item.tokenId === Number(name));
        } else {
          return data.filter((item) => item.username === name);
        }
      };

      console.log(tempTokenId()[0].tokenId);
      axios
        .get(proxyurl + url + Number(tempTokenId()[0].tokenId))
        .then((res) => {
          const user = res.data;

          if (user.pioneers !== undefined) {
            user.pioneers.sort((a, b) => a - b);
            pioneer.push(...user.pioneers);
          }
          if (user.bpos !== undefined) {
            user.bpos.sort((a, b) => a - b);
            bored.push(...user.bpos);
          }

          const unique = [...new Set(data.map((item) => item.username))];
          const unique2 = [...new Set(data2.map((item) => item.username))];

          const newData = unique
            .map((item) => {
              return data.find((x) => x.username === item);
            })
            .sort((a, b) => b.score - a.score);

          const newData2 = unique2
            .map((item) => {
              return data.find((x) => x.username === item);
            })
            .sort((a, b) => b.lockedTokens - a.lockedTokens);

          // find the index in the newData array
          const index = isNumber
            ? newData.findIndex((x) => x.tokenId === Number(name))
            : newData.findIndex((x) => x.username === name);
          const index2 = isNumber
            ? newData2.findIndex((x) => x.tokenId === Number(name))
            : newData2.findIndex((x) => x.username === name);

          const fav = tempTokenId()[0].favPFP;

          if (fav.includes("pioneer")) {
            let num = fav.replace("pioneer:", "");
            setSelectedNFTs([{ pioneer: Number(num) }]);
            console.log("pioneer");
          } else if (fav.includes("bored")) {
            let num = fav.replace("bored:", "");
            setSelectedNFTs([{ bored: Number(num) }]);
            console.log("bored");
          }

          const final = {
            _id: tempTokenId()[0]._id,
            username: tempTokenId()[0].username,
            score: tempTokenId()[0].score,
            lockedTokens: tempTokenId()[0].lockedTokens,
            pioneer: pioneer,
            status: tempTokenId()[0].status,
            bpos: bored,
            byScoreRank: index + 1,
            byTokenRank: index2 + 1,
            tokenId: tempTokenId()[0].tokenId,
            twitter: tempTokenId()[0].twitter,
            youtube: tempTokenId()[0].youtube,
            facebook: tempTokenId()[0].facebook,
            instagram: tempTokenId()[0].instagram,
            description: tempTokenId()[0].description,
            comments: tempTokenId()[0].comments,
            likes: tempTokenId()[0].likes,
            skills: tempTokenId()[0].skills,
            work: tempTokenId()[0].work,
          };

          console.log(tempTokenId()[0].work);

          setUser(final);
          setOwnerID(tempTokenId()[0].tokenId);
        })
        .then(() => {
          setIsLoading(false);
          setIsLoaded(true);
        });
    });
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const toggleSelectPioneer = (tokenId) => {
    setSelectedNFTs([{ pioneer: tokenId }]);

    console.log(selectedNFTs);
    console.log(tokenId);
    setSelectedNFT(!selectedNFT);
  };

  const toggleSelectBored = (tokenId) => {
    setSelectedNFTs([{ bored: tokenId }]);

    console.log(selectedNFTs);
    console.log(tokenId);
    setSelectedNFT(!selectedNFT);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const proxyurl = config.PROXY_URL;
    const tempLeaderboard = config.UPDATE;

    await axios
      .patch(proxyurl + tempLeaderboard + user._id, {
        twitter: formData.twitter === "" ? user.twitter : formData.twitter,
        youtube: formData.youtube === "" ? user.youtube : formData.youtube,
        facebook: formData.facebook === "" ? user.facebook : formData.facebook,
        instagram:
          formData.instagram === "" ? user.instagram : formData.instagram,
        description:
          formData.description === "" ? user.description : formData.description,
        favPFP:
          selectedNFTs[0].bored !== undefined
            ? `bored: ${selectedNFTs[0].bored}`
            : `pioneer: ${selectedNFTs[0].pioneer}`,
        skills: [...formData.skills, ...user.skills],
        work: formData.work === "" ? user.work : formData.work,
      })
      .then((res) => {
        if (res.data === "Post updated!") {
          alert("Updated successfully");
          window.location.reload();
        }
      })
      .catch((err) => {
        alert("Error updating");
        console.log(err.message);
        window.location.reload();
      });
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (window.ethereum !== undefined) {
      let chain = config.chainId.toString();
      if (window.ethereum.networkVersion === chain) {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((account) => {
            getInformation(account[0]);
          });
      } else {
        alert("Please connect to the correct network");
      }
    }
  };

  const toggleLike = async (e) => {
    setIsLoading(true);
    if (window.ethereum !== undefined) {
      let chain = config.chainId.toString();
      if (window.ethereum.networkVersion === chain) {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((account) => {
            submitLike(account[0]);
          });
      } else {
        alert("Please connect to the correct network");
      }
    }
  };

  return (
    <>
      {isEditing ? (
        <>
          {isConnected ? (
            <UserContainer>
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "20px",
                }}
                autoComplete="off"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "20px",
                  }}
                >
                  <UsernameText2>{user.username}.bored</UsernameText2>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid white",
                    padding: "10px",
                    paddingBottom: "30px",
                    width: "100%",
                    gap: "20px",
                    maxHeight: "500px",
                    borderRadius: "10px",
                  }}
                >
                  <SubtitleText>Socials</SubtitleText>
                  <div
                    style={{
                      alignItems: "flex-start",
                      display: "flex",
                      flexWrap: "wrap",
                      height: "100%",
                      gap: "20px",
                      justifyContent: "center",
                    }}
                  >
                    <Splitter2>
                      <StyledTwitterIcon />
                      <StyledInput
                        type="text"
                        name="twitter"
                        placeholder={
                          user.twitter === undefined || user.twitter === ""
                            ? "Twitter: None"
                            : user.twitter
                        }
                        value={twitter}
                        onChange={handleOnChange}
                      />
                    </Splitter2>
                    <Splitter2>
                      <StyledYoutubeIcon />
                      <StyledInput
                        type="text"
                        name="youtube"
                        placeholder={
                          user.youtube === undefined || user.youtube === ""
                            ? "Youtube: None"
                            : user.youtube
                        }
                        value={youtube}
                        onChange={handleOnChange}
                      />
                    </Splitter2>
                    <Splitter2>
                      <StyledFacebookIcon />
                      <StyledInput
                        type="text"
                        name="facebook"
                        placeholder={
                          user.facebook === undefined || user.facebook === ""
                            ? "Facebook: None"
                            : user.facebook
                        }
                        value={facebook}
                        onChange={handleOnChange}
                      />
                    </Splitter2>
                    <Splitter2>
                      <StyledInstagramIcon />
                      <StyledInput
                        type="text"
                        name="instagram"
                        placeholder={
                          user.instagram === undefined || user.instagram === ""
                            ? "Instagram: None"
                            : user.instagram
                        }
                        value={instagram}
                        onChange={handleOnChange}
                      />
                    </Splitter2>
                  </div>
                </div>
                <ThirdContainer
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    gap: "20px",
                  }}
                >
                  <SubtitleText>Profile Bio</SubtitleText>
                  <StyledInput2
                    type="text"
                    name="description"
                    placeholder={
                      user.description === undefined || user.description === ""
                        ? "About Me: None"
                        : user.description
                    }
                    value={description}
                    onChange={handleOnChange}
                  />
                </ThirdContainer>
                <ThirdContainer
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    gap: "20px",
                  }}
                >
                  <SubtitleText>Skills</SubtitleText>
                  <StyledInput2
                    type="text"
                    name="skills"
                    placeholder={
                      user.skills.length <= 0
                        ? "Skills: None (separate with commas)"
                        : user.skills
                    }
                    value={skills}
                    onChange={handleOnChange}
                  />
                </ThirdContainer>
                <ThirdContainer
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    gap: "20px",
                  }}
                >
                  <SubtitleText>Work</SubtitleText>
                  <StyledInput
                    type="text"
                    name="work"
                    placeholder={
                      user.work.length <= 0 ? "Work: None" : user.work
                    }
                    value={work}
                    onChange={handleOnChange}
                  />
                </ThirdContainer>
                <ThirdContainer>
                  <SubtitleText>Select Profile Picture</SubtitleText>
                  <BoredNFTContainer>
                    {user.pioneer.map((item, index) => (
                      <NFTCard4 key={index} tokenId={item} />
                    ))}
                    {user.bpos.map((item, index) => (
                      <NFTCard3 key={index} tokenId={item} />
                    ))}
                  </BoredNFTContainer>
                </ThirdContainer>
                <RoundButton onClick={handleSubmit}>Submit</RoundButton>
              </form>
            </UserContainer>
          ) : (
            <UserContainer>
              <RoundButton onClick={connect}>Connect</RoundButton>
            </UserContainer>
          )}
        </>
      ) : (
        <>
          {isLoading ? (
            <UserContainer
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>
                <FiLoader className="rotate" />
              </h1>
            </UserContainer>
          ) : (
            <>
              {isLoaded ? (
                <UserContainer>
                  <FirstContainer>
                    <BannerImage src={src()} alt="banner" />
                    <FirstBottomContainer>
                      <ProfileImageWrapper>
                        <ProfileImage
                          src={
                            selectedNFTs[0].bored !== undefined
                              ? src2(selectedNFTs[0].bored)
                              : src3(selectedNFTs[0].pioneer)
                          }
                          alt="profile"
                        />
                      </ProfileImageWrapper>
                      <ProfileImage3
                        src={
                          selectedNFTs[0].bored !== undefined
                            ? src2(selectedNFTs[0].bored)
                            : src3(selectedNFTs[0].pioneer)
                        }
                        alt="profile"
                      />
                      <Splitter
                        style={{
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "5px",
                          }}
                        >
                          <Splitter>
                            <UsernameText2>{user.username}.bored</UsernameText2>
                          </Splitter>

                          <Splitter
                            style={{
                              justifyContent: "flex-start",
                            }}
                          >
                            <Tippy
                              content={
                                <ParagraphText
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    backgroundColor: "white",
                                    padding: "10px",
                                    borderRadius: "10px",
                                  }}
                                >
                                  Rank based on score
                                </ParagraphText>
                              }
                            >
                              <Splitter
                                style={{
                                  gap: "8px",
                                }}
                              >
                                <StyledTrophyIcon />
                                <ParagraphText>
                                  {user.byScoreRank}
                                </ParagraphText>
                              </Splitter>
                            </Tippy>
                            <Tippy
                              content={
                                <ParagraphText
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    backgroundColor: "white",
                                    padding: "10px",
                                    borderRadius: "10px",
                                  }}
                                >
                                  Rank based on locked tokens
                                </ParagraphText>
                              }
                            >
                              <Splitter
                                style={{
                                  gap: "8px",
                                }}
                              >
                                <StyledSafeIcon />
                                <ParagraphText>
                                  {user.byTokenRank}
                                </ParagraphText>
                              </Splitter>
                            </Tippy>
                          </Splitter>
                          <BubbleContainer
                            style={{
                              outline: "none",
                              textAlign: "left",
                              padding: "0px",
                            }}
                          >
                            {user.likes.length} likes
                          </BubbleContainer>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledEditIcon onClick={toggleEdit} />
                          <StyledHeartIcon onClick={toggleLike} />
                        </div>
                      </Splitter>
                    </FirstBottomContainer>
                  </FirstContainer>

                  <RowContainer>
                    <FirstRow>
                      <SubtitleText
                        style={{
                          textAlign: "left",
                        }}
                      >
                        Profile Bio
                      </SubtitleText>
                      <ParagraphText
                        style={{
                          width: "100%",
                          textAlign: "center",
                          borderBottom: "1px solid gray",
                          paddingBottom: "15px",
                        }}
                      >
                        {user.description}
                      </ParagraphText>
                      <SubtitleText
                        style={{
                          textAlign: "left",
                        }}
                      >
                        Social Media Links
                      </SubtitleText>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          gap: "5px",
                        }}
                      >
                        <Splitter2>
                          <StyledTwitterIcon />
                          <a href={`https://twitter.com/${user.twitter}`}>
                            {user.twitter}
                          </a>
                        </Splitter2>
                        <Splitter2>
                          <StyledFacebookIcon />
                          <a href={`https://facebook.com/${user.facebook}`}>
                            {user.facebook}
                          </a>
                        </Splitter2>
                        <Splitter2>
                          <StyledInstagramIcon />
                          <a href={`https://instagram.com/${user.instagram}`}>
                            {user.instagram}
                          </a>
                        </Splitter2>
                        <Splitter2>
                          <StyledYoutubeIcon />
                          <a href={`https://youtube.com/${user.youtube}`}>
                            {user.youtube}
                          </a>
                        </Splitter2>
                      </div>
                      <SubtitleText
                        style={{
                          textAlign: "left",
                        }}
                      >
                        Skills
                      </SubtitleText>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          gap: "10px",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        {user.skills.length > 0 ? (
                          <>
                            {user.skills.map((skill) => (
                              <BubbleContainer>{skill}</BubbleContainer>
                            ))}
                          </>
                        ) : null}
                      </div>
                      <SubtitleText
                        style={{
                          textAlign: "left",
                        }}
                      >
                        Work
                      </SubtitleText>
                      <ParagraphText
                        style={{
                          textAlign: "left",
                          paddingLeft: "20px",
                        }}
                      >
                        Works at : {user.work}
                      </ParagraphText>
                    </FirstRow>
                    <SecondRow
                      style={{
                        justifyContent:
                          user.bpos.length + user.pioneer.length > 1
                            ? null
                            : "center",
                      }}
                    >
                      <Splitter2
                        style={{
                          justifyContent:
                            user.bpos.length + user.pioneer.length > 1
                              ? null
                              : "center",
                          width: "100%",
                        }}
                      >
                        <SubtitleText>My Bored Punks</SubtitleText>
                      </Splitter2>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          overflow: "auto",
                          gap: "5px",
                        }}
                      >
                        {user.pioneer.map((nft) => (
                          <NFTCard tokenId={nft} />
                        ))}
                        {user.bpos.map((nft) => (
                          <NFTCard2 tokenId={nft} />
                        ))}
                      </div>
                    </SecondRow>

                    <ThirdRow>
                      <StyledInput3
                        onChange={handleCommentChange}
                        name="comment"
                        value={comment}
                        placeholder="What is your message?"
                        autoComplete="off"
                      />
                      <StyledCommentIcon onClick={handleCommentSubmit} />
                    </ThirdRow>

                    <FourthRow>
                      <div
                        style={{
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        <UsernameText2
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {Number(user.score) === 0 ? "No Tier" : null}
                          {Number(user.score) > 0 && Number(user.score) < 5
                            ? "Tier 1 - Azure"
                            : null}
                          {Number(user.score) >= 5 && Number(user.score) < 10
                            ? "Tier 2 - Ruby"
                            : null}
                          {Number(user.score) >= 10 && Number(user.score) < 20
                            ? "Tier 3 - Gold"
                            : null}
                          {Number(user.score) >= 20
                            ? "Tier 4 - Amethyst"
                            : null}
                        </UsernameText2>
                        <TierStats
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          <TierData
                            style={{
                              borderRight: "2px solid white",
                            }}
                          >
                            <ParagraphText>Bored Punk NFTs</ParagraphText>
                            <SubtitleText>{user.bpos.length}</SubtitleText>
                          </TierData>
                          <TierData>
                            <ParagraphText>Bored Score</ParagraphText>
                            <SubtitleText>{user.score}</SubtitleText>
                          </TierData>
                          <TierData
                            style={{
                              borderLeft: "2px solid white",
                            }}
                          >
                            <ParagraphText>Status</ParagraphText>
                            <SubtitleText
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {user.status}
                            </SubtitleText>
                          </TierData>
                        </TierStats>
                      </div>
                    </FourthRow>

                    <FifthRow
                      style={{
                        justifyContent:
                          user.comments.length > 0 ? null : "center",
                      }}
                    >
                      {user.comments.length > 0 ? (
                        <SubtitleText
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Profile Comments
                        </SubtitleText>
                      ) : null}

                      <NFTContainer>
                        {user.comments.length > 0 ? (
                          <>
                            {user.comments.map((comment) => (
                              <CommentCard
                                comment={comment.comment}
                                user={comment.user}
                                pfp={comment.pfp}
                                tokenId={comment.tokenId}
                              />
                            ))}
                          </>
                        ) : (
                          <ParagraphText>
                            No comments yet. Be the first to comment!
                          </ParagraphText>
                        )}
                      </NFTContainer>
                    </FifthRow>
                  </RowContainer>
                  <MottoContainer>
                    <UsernameText>educate.</UsernameText>
                    <UsernameText
                      style={{
                        color: "#0BBC87",
                      }}
                    >
                      invest.
                    </UsernameText>
                    <UsernameText>thrive.</UsernameText>
                  </MottoContainer>
                </UserContainer>
              ) : (
                <UserContainer
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isNumber ? (
                    <RoundButton onClick={getData}>
                      Load Token #{props.match.params.user}'s data
                    </RoundButton>
                  ) : (
                    <RoundButton onClick={getData}>
                      Load {props.match.params.user}.bored's data
                    </RoundButton>
                  )}
                </UserContainer>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(User);
