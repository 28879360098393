import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";
import BTNBG from "../assets/gif/BTNBG.gif";
import scarlet4 from "../assets/images/M3A2.png";
import scarlet3 from "../assets/images/M3A3.png";

import config from "../config/config.json";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  gap: 20px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${scarlet4});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const FormLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  gap: 20px;
`;

const StyledImage = styled.img`
  width: 500px;
  height: 500px;
  border-radius: 10px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  gap: 10px;
`;

const StyledParagraph = styled.p`
  font-size: 13px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const RoundButton = styled.button`
  background-image: url(${BTNBG});
  background-repeat: no-repeat;
  background-size: cover;
  width: 200px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50px;
  color: white;
`;

const StyledInput = styled.input`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  color: white;
  background-color: transparent;
  min-width: 350px;
  border-bottom: 1px solid white;
  :focus {
    outline: none;
  }
  ::-webkit-input-placeholder {
    color: white;
  }
`;

const RedeemForm = ({ address }) => {
  const [email, setEmail] = useState("");

  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    // check if email is empty
    if (email === "") {
      alert("Please enter your email");
      return;
    }
    // check if email is valid
    if (!email.includes("@")) {
      alert("Please enter a valid email");
      return;
    }
    try {
      const response = await axios.post(config.SB_API_URL + config.SB_API_KEY, [
        {
          email: email,
          wallet: address,
        },
      ]);
      if (response.data.message === "Successfully Inserted") {
        alert("Successfully Submitted");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormContainer>
      <FormLayout>
        <FormWrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "400px",
              gap: "10px",
            }}
          >
            <h2>M3R S3A: Web3 by the Sea</h2>
            <StyledParagraph>
              The Official Philippine Web3 Festival “Thank You” Afterparty 🎉!
            </StyledParagraph>
            <StyledParagraph>
              <b>WHEN</b>: Nov. 18 - 20, 2022 <br />
              <b>WHERE</b>: Flotsam & Jetsam Hostel, San Juan, La Union
            </StyledParagraph>
            <StyledParagraph>
              Party with builders, VCs, entrepreneurs, gamers, degens, crypto
              OGs, and the bored community on November 18 - 20 at La Union! Rico
              Blanco, one of the famous rockstars in PH will also be celebrating
              with us by having a concert! 🔥 See you there!
            </StyledParagraph>
            <StyledParagraph>
              As the official community partner for the event, the bored
              community will get up to 50% discount on the upcoming event! See
              you there and let's have some fun! 🥳
            </StyledParagraph>
            <StyledParagraph>
              <b>HOW TO PARTICIPATE?</b> <br />
              💌 RSVP here. Limited slots only! 🔗
              <a
                style={{
                  color: "lightblue",
                }}
                href="lu.ma/M3RS3AWeb3bytheSea"
              >
                lu.ma/M3RS3AWeb3bytheSea
              </a>
            </StyledParagraph>
            <StyledParagraph>
              Apply code "<b>BOR3D</b>" to get a 50% Discount!
            </StyledParagraph>
            <StyledParagraph>
              Join this M3RS3A telegram group chat for more announcements and
              updates!
              <a
                style={{
                  color: "lightblue",
                }}
                href="https://t.me/M3RS3AWeb3bytheSea"
              >
                https://t.me/M3RS3AWeb3bytheSea
              </a>
            </StyledParagraph>
            <StyledParagraph>
              Celebrate with us on a weekend surf getaway! 🏄‍♂️🌊🏄‍♀️
            </StyledParagraph>
            <StyledParagraph>
              #BPOS #Web3bytheSea #PHWeb3Fest #M3RS3A
            </StyledParagraph>
          </div>
          <ImageWrapper>
            <StyledImage src={scarlet3} alt="Scarletbox" />
          </ImageWrapper>
        </FormWrapper>
      </FormLayout>
    </FormContainer>
  );
};

export default RedeemForm;
