import React from "react";
import styled from "styled-components";

const FooterContainer = styled.div`
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: black;
  gap: 10px;
  width: 100%;
`;

const FooterCopyright = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const FooterSocials = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const FooterLinks = styled.a`
  text-decoration: none;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterCopyright>
        <p>
          ​© 2021 The Coin Bubble  ||  Bored Punks of Society, All Rights
          Reserved.  ||  boredpunksociety@gmail.com
        </p>
      </FooterCopyright>
      <FooterSocials>
        <FooterLinks
          href="
            https://www.facebook.com/boredpunksociety/"
        >
          Facebook
        </FooterLinks>
        {"|"}
        <FooterLinks
          href="
            https://twitter.com/boredpunksociety"
        >
          Twitter
        </FooterLinks>
        {"|"}
        <FooterLinks
          href="
            https://discord.gg/boredpunksociety"
        >
          Discord
        </FooterLinks>
        {"|"}
        <FooterLinks
          href="
            https://medium.com/@boredpunksociety"
        >
          Medium
        </FooterLinks>
        {"|"}
        <FooterLinks
          href="
            https://spotify.com/boredpunksociety"
        >
          YouTube
        </FooterLinks>
      </FooterSocials>
    </FooterContainer>
  );
};

export default Footer;
