import React from "react";
import { FiChevronsRight } from "react-icons/fi";
import styled from "styled-components";

const RedeemCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-evenly;
  background-color: rgba(0, 0, 0, 0.6);
  height: max-content;
  width: 320px;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
`;

const RedeemImage = styled.img`
  border-radius: 10px;
  max-width: 100%;
`;

const RedeemTitle = styled.h2``;

const RedeemDiscription = styled.p`
  font-size: 13px;
`;

const BottomContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ProfileImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
`;

const Splitter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const StyledChevronsRight = styled(FiChevronsRight)`
  font-size: 20px;
  color: #fff;
  :hover {
    cursor: pointer;
  }
`;

const RedeemCard = ({
  toggle,
  src,
  title,
  description,
  displayImg,
  company,
  isClickable,
  isExpired,
}) => {
  return (
    <RedeemCardContainer>
      <RedeemImage
        src={src}
        style={{
          filter: `${isExpired ? "grayscale(100%)" : "none"}`,
        }}
      />
      <RedeemTitle>{title}</RedeemTitle>
      <RedeemDiscription>{description}</RedeemDiscription>
      <BottomContainer>
        <Splitter>
          <ProfileImage
            src={displayImg}
            style={{
              filter: `${isExpired ? "grayscale(100%)" : "none"}`,
            }}
          />
          <p>{company}</p>
        </Splitter>
        {isClickable ? <StyledChevronsRight onClick={toggle} /> : null}
      </BottomContainer>
    </RedeemCardContainer>
  );
};

export default RedeemCard;
