import React from "react";
import { FiLink } from "react-icons/fi";
import { RiUserSearchLine } from "react-icons/ri";
import styled from "styled-components";
import BTNBG from "../assets/gif/BTNBG.gif";
import Elite from "../assets/images/Elite.png";
import Holder from "../assets/images/Holder.png";
import Monarch from "../assets/images/Monarch.png";
import None from "../assets/images/None.png";
import Pioneer from "../assets/images/Pioneer.png";
import Premier from "../assets/images/Premier.png";

const TeamContainer = styled.div`
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Splitter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledTags = styled.div`
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  font-size: 14px;
  gap: 10px;
  :hover {
    cursor: pointer;
  }
`;

const StyledUserSearch = styled(RiUserSearchLine)`
  color: #fff;
  font-size: 20px;
`;

const StyledLink = styled(FiLink)`
  color: #fff;
  font-size: 20px;
`;

const RoundButton = styled.div`
  color: #fff;
  background-color: #31b07c;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
  border-radius: 50px;
  font-size: 14px;
  gap: 10px;
  :hover {
    cursor: pointer;
  }
`;

const TeamCardContainer = styled.div``;

const TeamBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
  background-image: url(${Elite});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 200px;
  height: 70px;
  border-radius: 20px 20px 0px 0px;
`;

const TeamProfileImage = styled.div`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 9999;
`;

const TeamDetailsContainer = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  min-height: 180px;
  border-radius: 0px 0px 20px 20px;
`;

const TeamMembers = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 10px;
  overflow: auto;
  :hover {
    ::-webkit-scrollbar {
      display: block;
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
  }
`;

const TeamCard = ({ name, tokenId, role }) => {
  return (
    <TeamCardContainer>
      <TeamBanner>
        <div>
          <TeamProfileImage
            src={`https://media.raritysniper.com/bored-punks-of-society/${tokenId}-600.webp?cacheId=3`}
          />
        </div>
      </TeamBanner>
      <TeamDetailsContainer>
        <h2>{name}</h2>
        <p
          style={{
            textAlign: "center",
          }}
        >
          {role}
        </p>
        <RoundButton>Following</RoundButton>
      </TeamDetailsContainer>
    </TeamCardContainer>
  );
};

const CompanyTeam = ({ team, name }) => {
  return (
    <TeamContainer>
      <Splitter
        style={{
          gap: "20px",
        }}
      >
        <h1>The {name} Team</h1>
        <StyledTags>
          <StyledUserSearch />
          Now Hiring
        </StyledTags>
        <StyledTags>
          <StyledLink />
          Connect
        </StyledTags>
      </Splitter>
      <TeamMembers>
        {team.map((member) => {
          return (
            <TeamCard
              name={member.name}
              tokenId={member.tokenId}
              role={member.role}
            />
          );
        })}
      </TeamMembers>
    </TeamContainer>
  );
};

export default CompanyTeam;
