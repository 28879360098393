import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiLoader } from "react-icons/fi";
import styled from "styled-components";
import BTNBG from "../assets/gif/BTNBG.gif";
import b3 from "../assets/images/b3.png";
import LeaderboardsList from "../components/LeaderboardsList";
import Navbar from "../components/Navbar";
import Pagination from "../components/Pagination";
import config from "../config/config.json";

const { ethers } = require("ethers");

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  gap: 20px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${b3});

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 50px;
`;

const RoundButton = styled.button`
  background-image: url(${BTNBG});
  background-repeat: no-repeat;
  background-size: cover;
  width: 200px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50px;
  color: white;
`;

const ProfileSplitter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledSelect = styled.select`
  height: 35px;
  border: none;
  border-radius: 50px;
  display: flex;
  justifycontent: center;
  alignitems: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  text-align: center;
  padding: 0 20px;
`;

const StyledOption = styled.option`
  text-align: center;
`;

const Profile = () => {
  const [loading, setLoading] = useState(false);

  const [leaderboards, setLeaderboards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(5);

  const [currentFilter, setCurrentFilter] = useState("score");

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;

  const [leaderboardByScore, setLeaderboardByScore] = useState([]);
  const [leaderboardByTokens, setLeaderboardByTokens] = useState([]);
  const [leaderboardByLikes, setLeaderboardByLikes] = useState([]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleChange = (e) => {
    setCurrentFilter(e.target.value);
  };

  const email = "itsnotjie@gmail.com";

  const test = async () => {
    setLoading(true);
    await axios
      .get(config.PROXY_URL + config.LEADERBOARD, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        const data2 = res.data;
        const data3 = res.data;

        // remove username duplicates
        const unique = [...new Set(data.map((item) => item.username))];
        const unique2 = [...new Set(data2.map((item) => item.username))];
        const unique3 = [...new Set(data3.map((item) => item.username))];

        const newData = unique
          .map((item) => {
            return data.find((x) => x.username === item);
          })
          .sort((a, b) => b.score - a.score);

        const newData2 = unique2
          .map((item) => {
            return data.find((x) => x.username === item);
          })
          .sort((a, b) => b.lockedTokens - a.lockedTokens);

        const newData3 = unique3
          .map((item) => {
            return data.find((x) => x.username === item);
          })
          .sort((a, b) => b.likes.length - a.likes.length);

        setLeaderboardByScore(newData);
        setLeaderboardByTokens(newData2);
        setLeaderboardByLikes(newData3);
        setLoading(false);
      })
      .catch((err) => {
        alert(err.message);
        window.location.reload();
      });
  };

  return (
    <ProfileContainer>
      <Navbar />
      <h1
        style={{
          marginTop: "100px",
        }}
      >
        Leaderboard
      </h1>
      {leaderboardByScore.length > 0 ? (
        <StyledSelect onChange={handleChange}>
          <StyledOption value="score">Score</StyledOption>
          <StyledOption value="locked">Locked Tokens</StyledOption>
          <StyledOption value="likes">Likes</StyledOption>
        </StyledSelect>
      ) : null}

      {loading ? (
        <FiLoader
          size={30}
          style={{
            color: "white",
          }}
          className="rotate"
        />
      ) : (
        <>
          {leaderboardByScore.length > 0 && leaderboardByTokens.length > 0 ? (
            <>
              <LeaderboardsList
                list={
                  currentFilter === "score"
                    ? leaderboardByScore
                    : currentFilter === "locked"
                    ? leaderboardByTokens
                    : currentFilter === "likes"
                    ? leaderboardByLikes
                    : null
                }
                indexOfFirstPost={indexOfFirstPost}
                indexOfLastPost={indexOfLastPost}
              />
              <Pagination
                postsPerPage={postPerPage}
                totalPosts={
                  currentFilter === "score"
                    ? leaderboardByScore.length
                    : currentFilter === "locked"
                    ? leaderboardByTokens.length
                    : currentFilter === "likes"
                    ? leaderboardByLikes.length
                    : null
                }
                paginate={paginate}
                handeChange={handleChange}
              />
            </>
          ) : (
            <RoundButton onClick={test}>Load Leaderboards</RoundButton>
          )}
        </>
      )}
    </ProfileContainer>
  );
};

export default Profile;
