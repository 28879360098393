import { FiCheckSquare, FiEdit3, FiLoader, FiSquare } from "react-icons/fi";
import React, { useEffect, useState } from "react";

import BG from "../assets/images/BG.png";
import BPOS from "../assets/images/BPOS.png";
import BTNBG from "../assets/gif/BTNBG.gif";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import RedeemForm from "../components/RedeemForm";
import RedeemForm2 from "../components/RedeemForm2";
import RedeemingComponent from "../components/RedeemingComponent";
import axios from "axios";
import b2 from "../assets/images/b2.png";
import bored from "../abi/bored.json";
import { concat } from "ethers/lib/utils";
import config from "../config/config.json";
import emailjs from "emailjs-com";
import locking from "../abi/locking.json";
import pioneer from "../abi/pioneer.json";
import points from "../data/points.json";
import styled from "styled-components";

const { ethers } = require("ethers");

const LockingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${b2});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const LockingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const Staked = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Unstaked = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const TokenContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;

const RoundButton = styled.button`
  background-image: url(${BTNBG});
  background-repeat: no-repeat;
  background-size: cover;
  width: 200px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50px;
  color: white;
`;

const RoundButton2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  border-radius: 50px;
  width: 200px;
  padding: 10px;
  color: white;
  background-color: transparent;
  :hover {
    cursor: pointer;
  }
`;

const TierContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TierStats = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: center;
  align-items: center;
`;
const TierData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 200px;
`;

const NFTCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

const StyledInput = styled.input`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  color: white;
  background-color: transparent;
  min-width: 350px;
  border-bottom: 1px solid white;
  :focus {
    outline: none;
  }
  ::-webkit-input-placeholder {
    color: white;
  }
`;

const StyledCheckbox = styled(FiCheckSquare)`
  :hover {
    cursor: pointer;
  }
`;

const StyledSquare = styled(FiSquare)`
  :hover {
    cursor: pointer;
  }
`;

const unstakedPioneerNFT = [];
const unstakedBoredNFT = [];
const stakedPioneerNFT = [];
const stakedBoredNFT = [];
let ownerPoints = 0;
let ownerID = 0;
const usernameDatabase = [];

const Locking = () => {
  const [tempUnstakedPioneerNFT, setTempUnstakedPioneerNFT] =
    useState(unstakedPioneerNFT);
  const [tempUnstakedBoredNFT, setTempUnstakedBoredNFT] =
    useState(unstakedBoredNFT);
  const [tempStakedPioneerNFT, setTempStakedPioneerNFT] =
    useState(stakedPioneerNFT);
  const [tempStakedBoredNFT, setTempStakedBoredNFT] = useState(stakedBoredNFT);

  const [tempTime, setTempTime] = useState(0);
  const [finalTime, setFinalTime] = useState(0);

  const [hasUsername, setHasUsername] = useState(false);

  const [defaultUsername, setDefaultUsername] = useState("");

  const [usernameText, setUsernameText] = useState("");

  const [usernameError, setUsernameError] = useState("");

  const [hasUsernameError, setHasUsernameError] = useState(false);

  const [isClaiming, setIsClaiming] = useState(false);

  const [isAgreeing, setIsAgreeing] = useState(false);

  const [isClaimable, setIsClaimable] = useState(false);

  const [isRedeeeming, setIsRedeeeming] = useState(false);

  const [isRedeemForm, setIsRedeemForm] = useState(false);

  const toggleAgreeing = () => {
    setIsAgreeing(!isAgreeing);
  };

  const toggleSelectStakedPioneerNFT = (id) => {
    let temp = [...tempUnstakedPioneerNFT];
    temp[id].selected = !temp[id].selected;
    setTempUnstakedPioneerNFT(temp);
  };

  const toggleSelectStakedBoredNFT = (id) => {
    let temp = [...tempUnstakedBoredNFT];
    temp[id].selected = !temp[id].selected;
    setTempUnstakedBoredNFT(temp);
  };

  const toggleSelectUnstakedPioneerNFT = (id) => {
    let temp = [...tempStakedPioneerNFT];
    temp[id].selected = !temp[id].selected;
    setTempStakedPioneerNFT(temp);
  };

  const toggleSelectUnstakedBoredNFT = (id) => {
    let temp = [...tempStakedBoredNFT];
    temp[id].selected = !temp[id].selected;
    setTempStakedBoredNFT(temp);
  };

  const stakeAll = async () => {
    let temp = tempUnstakedPioneerNFT.filter((nft) => nft.selected);
    let temp2 = tempUnstakedBoredNFT.filter((nft) => nft.selected);
    let res = temp.map((nft) => nft.tokenId);
    let res2 = temp2.map((nft) => nft.tokenId);

    let tempGasLimitPioneer = res.length * config.gasLimit;
    let tempGasLimitBored = res2.length * config.gasLimit;

    console.log(res);
    console.log(res2);
    if (res.length > 0) {
      await lockingContract
        .stakeBatchPioneer(res, {
          gasLimit: tempGasLimitPioneer,
        })
        .then((tx) => {
          tx.wait().then(() => {
            if (res2.length <= 0) {
              alert(`Successfully locked NFTs`);
              window.location.reload();
            }
          });
        });
    }
    if (res2.length > 0) {
      await lockingContract
        .stakeBatchBPOS(res2, {
          gasLimit: tempGasLimitBored,
        })
        .then((tx) => {
          tx.wait().then(() => {
            if (res.length <= 0) {
              alert(`Successfully locked NFTs`);
              window.location.reload();
            }
            if (res.length > 0 && res2.length > 0) {
              alert(`Successfully locked NFTs`);
              window.location.reload();
            }
          });
        });
    }
  };

  const selectAllStakedNFT = () => {
    let temp = [...tempStakedPioneerNFT];
    temp.forEach((nft) => (nft.selected = !nft.selected));
    setTempStakedPioneerNFT(temp);
    let temp2 = [...tempStakedBoredNFT];
    temp2.forEach((nft) => (nft.selected = !nft.selected));
    setTempStakedBoredNFT(temp2);
  };

  const selectAllUnstakedNFT = () => {
    let temp = [...tempUnstakedPioneerNFT];
    temp.forEach((nft) => (nft.selected = !nft.selected));
    setTempUnstakedPioneerNFT(temp);
    let temp2 = [...tempUnstakedBoredNFT];
    temp2.forEach((nft) => (nft.selected = !nft.selected));
    setTempUnstakedBoredNFT(temp2);
  };

  const unstakeAll = async () => {
    let temp = tempStakedPioneerNFT.filter((nft) => nft.selected);
    let temp2 = tempStakedBoredNFT.filter((nft) => nft.selected);
    let res = temp.map((nft) => nft.tokenId);
    let res2 = temp2.map((nft) => nft.tokenId);

    let tempGasLimitPioneer = res.length * config.gasLimit;
    let tempGasLimitBored = res2.length * config.gasLimit;

    console.log(res);
    console.log(res2);
    if (res.length > 0) {
      await lockingContract
        .unstakeBatchPioneer(res, ownerID, {
          gasLimit: tempGasLimitPioneer,
        })
        .then((tx) => {
          tx.wait().then(() => {
            if (res2.length <= 0) {
              alert(`Successfully unlocked NFTs`);
              window.location.reload();
            }
          });
        });
    }
    if (res2.length > 0) {
      await lockingContract
        .unstakeBatchBPOS(res2, ownerID, {
          gasLimit: tempGasLimitBored,
        })
        .then((tx) => {
          tx.wait().then(() => {
            if (res.length <= 0) {
              alert(`Successfully unlocked NFTs`);
              window.location.reload();
            }
            if (res.length > 0 && res2.length > 0) {
              alert(`Successfully unlocked NFTs`);
              window.location.reload();
            }
          });
        });
    }
  };

  const NFTCard = ({ tokenId, selected, staked, index }) => {
    const suffix = tokenId > 100 && tokenId <= 115 ? ".gif" : ".png";

    return (
      <NFTCardContainer onClick={() => toggleSelectStakedPioneerNFT(index)}>
        <img
          style={{
            maxWidth: "200px",
            maxHeight: "200px",
            borderRadius: "20px 20px 0 0",
          }}
          src={`https://bpos-db.herokuapp.com/pioneer/${tokenId}${suffix}`}
          alt="NFT"
        />
        <div
          style={{
            minHeight: "30px",
            minWidth: "200px",
            backgroundColor: "rgba(0,0,0,0.4)",
            color: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 15px",
            borderRadius: "0 0 20px 20px",
          }}
        >
          <h2>#{tokenId}</h2>
          {selected ? <FiCheckSquare size={30} /> : <FiSquare size={30} />}
        </div>
      </NFTCardContainer>
    );
  };

  const NFTCard2 = ({ tokenId, selected, staked, index }) => {
    return (
      <NFTCardContainer onClick={() => toggleSelectStakedBoredNFT(index)}>
        <img
          style={{
            maxWidth: "200px",
            maxHeight: "200px",
            borderRadius: "20px 20px 0 0",
          }}
          src={`https://media.raritysniper.com/bored-punks-of-society/${tokenId}-600.webp?cacheId=3`}
          alt="NFT"
        />
        <div
          style={{
            minHeight: "30px",
            minWidth: "200px",
            backgroundColor: "rgba(0,0,0,0.4)",
            color: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 15px",
            borderRadius: "0 0 20px 20px",
          }}
        >
          <h2>#{tokenId}</h2>
          {selected ? <FiCheckSquare size={25} /> : <FiSquare size={25} />}
        </div>
      </NFTCardContainer>
    );
  };

  const NFTCard3 = ({ tokenId, selected, staked, index, finalTime }) => {
    const suffix = tokenId > 100 && tokenId <= 115 ? ".gif" : ".png";

    return (
      <NFTCardContainer onClick={() => toggleSelectUnstakedPioneerNFT(index)}>
        <img
          style={{
            maxWidth: "200px",
            maxHeight: "200px",
            borderRadius: "20px 20px 0 0",
          }}
          src={`https://bpos-db.herokuapp.com/pioneer/${tokenId}${suffix}`}
          alt="NFT"
        />
        <div
          style={{
            minHeight: "30px",
            minWidth: "200px",
            backgroundColor: "rgba(0,0,0,0.4)",
            color: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 15px",
            borderRadius: "0 0 20px 20px",
          }}
        >
          <h2>#{tokenId}</h2>
          {finalTime <= 0 ? (
            <>
              {selected ? <FiCheckSquare size={25} /> : <FiSquare size={25} />}
            </>
          ) : null}
        </div>
      </NFTCardContainer>
    );
  };

  const NFTCard4 = ({ tokenId, selected, staked, index, finalTime }) => {
    return (
      <NFTCardContainer onClick={() => toggleSelectUnstakedBoredNFT(index)}>
        <img
          style={{
            maxWidth: "200px",
            maxHeight: "200px",
            borderRadius: "20px 20px 0 0",
          }}
          src={`https://media.raritysniper.com/bored-punks-of-society/${tokenId}-600.webp?cacheId=3`}
          alt="NFT"
        />
        <div
          style={{
            minHeight: "30px",
            minWidth: "200px",
            backgroundColor: "rgba(0,0,0,0.4)",
            color: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 15px",
            borderRadius: "0 0 20px 20px",
          }}
        >
          <h2>#{tokenId}</h2>
          {finalTime <= 0 ? (
            <>
              {selected ? <FiCheckSquare size={25} /> : <FiSquare size={25} />}
            </>
          ) : null}
        </div>
      </NFTCardContainer>
    );
  };

  const [isConnected, setIsConnected] = useState(false);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  const [pioneerContract, setPioneerContract] = useState(null);
  const [boredContract, setBoredContract] = useState(null);
  const [lockingContract, setLockingContract] = useState(null);

  const [pioneerProvider, setPioneerProvider] = useState(null);
  const [boredProvider, setBoredProvider] = useState(null);
  const [lockingProvider, setLockingProvider] = useState(null);

  const [pioneerSigner, setPioneerSigner] = useState(null);
  const [boredSigner, setBoredSigner] = useState(null);
  const [lockingSigner, setLockingSigner] = useState(null);

  const [totalStaked, setTotalStaked] = useState(0);
  const [totalUnstaked, setTotalUnstaked] = useState(0);

  const connect = () => {
    setIsLoading(true);
    if (window.ethereum !== undefined) {
      let chain = config.chainId.toString();
      if (window.ethereum.networkVersion === chain) {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((account) => {
            accountChangedHandler(account[0]).then((res) => {
              getData(res).then(() => {
                setIsConnected(true);
                setIsLoading(false);
              });
            });
          });
      } else {
        alert("Please connect to the correct network");
      }
    } else {
      setIsConnected(false);
    }
  };

  const accountChangedHandler = async (account) => {
    setDefaultAccount(account);
    updateEthers();
    return account;
  };

  const updateEthers = () => {
    let tempPioneerProvider = new ethers.providers.Web3Provider(
      window.ethereum
    );
    let tempPioneerSigner = tempPioneerProvider.getSigner();
    let tempBoredProvider = new ethers.providers.Web3Provider(window.ethereum);
    let tempBoredSigner = tempBoredProvider.getSigner();
    let tempLockingProvider = new ethers.providers.Web3Provider(
      window.ethereum
    );
    let tempLockingSigner = tempLockingProvider.getSigner();

    let lockingContract = new ethers.Contract(
      config.lockingAddress,
      locking,
      tempLockingSigner
    );
    let tempPioneerContract = new ethers.Contract(
      config.pioneerAddress,
      pioneer,
      tempPioneerSigner
    );
    let tempBoredContract = new ethers.Contract(
      config.boredAddress,
      bored,
      tempBoredSigner
    );

    setPioneerProvider(tempPioneerProvider);
    setPioneerSigner(tempPioneerSigner);
    setPioneerContract(tempPioneerContract);

    setBoredProvider(tempBoredProvider);
    setBoredSigner(tempBoredSigner);
    setBoredContract(tempBoredContract);

    setLockingContract(lockingContract);
    setLockingSigner(tempLockingSigner);
    setLockingProvider(tempLockingProvider);
  };

  const getData = async (account) => {
    const url = config.LEADERBOARD;
    const proxy = config.PROXY_URL;
    const submit = config.SUBMIT_LB;
    let balance = await pioneerContract.balanceOf(account);
    let balance2 = await boredContract.balanceOf(account);
    let approved = await lockingContract.isApproved(account);
    let walletBalance = await lockingContract
      .walletOfOwner(account)
      .then((res) => {
        ownerID = Number(res.toString());
        return res.toString();
      });

    const usernames = await axios.get(proxy + url).then((res) => {
      return res.data;
    });

    usernameDatabase.push(...usernames);

    if (Number(walletBalance.toString()) > 0) {
      await lockingContract
        .stakedPioneerNFTSByUser(Number(walletBalance.toString()))
        .then((res) => {
          res.map((item) => {
            if (item.toString() !== "0") {
              let tokenId = Number(item.toString());

              ownerPoints +=
                tokenId > 100 && tokenId <= 115 ? 20 : tokenId <= 15 ? 20 : 15;
              stakedPioneerNFT.push({
                tokenId: tokenId,
                selected: false,
                staked: true,
              });
            }
          });
        });

      await lockingContract
        .stakedBPOSNFTSByUser(Number(walletBalance.toString()))
        .then((res) => {
          res.map((item) => {
            if (item.toString() !== "0") {
              const point = points.find(
                (point) => point.tokenId === Number(item.toString())
              );

              ownerPoints += point.points;

              stakedBoredNFT.push({
                tokenId: Number(item.toString()),
                selected: false,
                staked: true,
              });
            }
          });
        });
    }

    setIsApproved(approved);

    for (let i = 0; i < balance.toString(); i++) {
      let id = await pioneerContract.tokenOfOwnerByIndex(account, i);
      unstakedPioneerNFT.push({
        tokenId: Number(id.toString()),
        selected: false,
        staked: false,
      });
    }
    for (let i = 0; i < balance2.toString(); i++) {
      let id = await boredContract.tokenOfOwnerByIndex(account, i);
      unstakedBoredNFT.push({
        tokenId: Number(id.toString()),
        selected: false,
        staked: false,
      });
    }

    // sort by tokenId
    stakedPioneerNFT.sort((a, b) => {
      return a.tokenId - b.tokenId;
    });
    stakedBoredNFT.sort((a, b) => {
      return a.tokenId - b.tokenId;
    });
    unstakedPioneerNFT.sort((a, b) => {
      return a.tokenId - b.tokenId;
    });
    unstakedBoredNFT.sort((a, b) => {
      return a.tokenId - b.tokenId;
    });

    if (stakedBoredNFT.length + stakedPioneerNFT.length > 0) {
      let time = await lockingContract.unlockOnTimeOF(
        Number(walletBalance.toString())
      );

      let time2 = Date.now() / 1000;
      setTempTime(time.toString());
      setFinalTime(time.toString() - time2);
    }

    const status = () => {
      if (stakedBoredNFT.length <= 0) {
        if (stakedPioneerNFT.length > 0) {
          return "Pioneer";
        }
        return "None";
      }

      if (stakedBoredNFT.length > 0 && stakedBoredNFT.length < 5) {
        if (stakedPioneerNFT.length > 0) {
          return "Holder x Pioneer";
        }
        return "Holder";
      }

      if (stakedBoredNFT.length >= 5 && stakedBoredNFT.length < 10) {
        if (stakedPioneerNFT.length > 0) {
          return "Premier x Pioneer";
        }
        return "Premier";
      }

      if (stakedBoredNFT.length >= 10 && stakedBoredNFT.length < 20) {
        if (stakedPioneerNFT.length > 0) {
          return "Elite x Pioneer";
        }
        return "Elite";
      }

      if (stakedBoredNFT.length >= 20) {
        if (stakedPioneerNFT.length > 0) {
          return "Monarch x Pioneer";
        }
        return "Monarch";
      }
    };

    if (ownerID > 0) {
      await lockingContract
        .usernames(Number(walletBalance.toString()))
        .then((res) => {
          let tempName = res.toString();
          console.log(tempName);
          if (tempName.length === 0) {
            setUsernameText(tempName);
            setHasUsername(true);
            console.log("Has no username");
          }
          if (tempName.length > 0) {
            const filtered = usernameDatabase.filter((item) => {
              return item.username === tempName;
            });

            if (filtered.length === 0 || filtered[0] === undefined) {
              console.log("Has no username database");
              fetch(proxy + submit, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  username: tempName,
                  score: ownerPoints,
                  lockedTokens: stakedBoredNFT.length + stakedPioneerNFT.length,
                  status: status(),
                  tokenId: Number(walletBalance.toString()),
                  favPFP: "bored:0",
                }),
              }).catch((err) => {
                alert("Error submitting data to database");
              });
            } else {
              console.log("Has username database");
              fetch(proxy + config.UPDATE + filtered[0]._id, {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  username: tempName,
                  tokenId: Number(walletBalance.toString()),
                  lockedTokens: stakedBoredNFT.length + stakedPioneerNFT.length,
                  score: ownerPoints,
                  status: status(),
                }),
              }).catch((err) => {
                alert("Error submitting data to database");
              });
            }

            setUsernameText(tempName);
            setHasUsername(false);
            console.log("Has username");
          }
        });
    }

    let claimed = await lockingContract.isClaimed(ownerID).then((res) => {
      return res;
    });

    setIsClaimable(claimed);

    setTotalStaked(stakedBoredNFT.length + stakedPioneerNFT.length);
    setTotalUnstaked(unstakedPioneerNFT.length + unstakedBoredNFT.length);
    setTempUnstakedPioneerNFT(unstakedPioneerNFT);
    setTempUnstakedBoredNFT(unstakedBoredNFT);
    setTempStakedPioneerNFT(stakedPioneerNFT);
    setTempStakedBoredNFT(stakedBoredNFT);
    console.log(
      stakedPioneerNFT.length,
      stakedBoredNFT.length,
      unstakedPioneerNFT.length,
      unstakedBoredNFT.length,
      usernameText
    );
  };

  const approve = async () => {
    setIsLoading(true);
    await pioneerContract
      .setApprovalForAll(config.lockingAddress, true, {
        from: defaultAccount,
      })
      .then((tx) => {
        setLoadingText("Approving locking contract to pioneer contract...");
      });
    await boredContract
      .setApprovalForAll(config.lockingAddress, true, {
        from: defaultAccount,
      })
      .then((tx) => {
        setLoadingText("Approving locking contract to bored contract...");
        tx.wait().then((tx) => {
          lockingContract.approveContracts().then((tx) => {
            setLoadingText("Finalizing...");
            tx.wait().then((tx) => {
              alert("Successfully approved the contract");
              window.location.reload();
            });
          });
        });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const username = await usernameDatabase.find(
      (username) => username.username === defaultUsername
    );

    // require username to be letters only
    if (/^[a-zA-Z]+$/.test(defaultUsername)) {
      if (username === undefined) {
        if (usernameText.length > 0) {
          await lockingContract
            .setUsername(defaultUsername, ownerID, {
              gasLimit: 1000000,
              value: ethers.utils.parseEther("25"),
            })
            .then((tx) => {
              tx.wait()
                .then((tx) => {
                  alert("Successfully set username!");
                  window.location.reload();
                })
                .catch((err) => {
                  alert("Error setting username");
                });
            })
            .catch((err) => {
              alert("Something went wrong!");
              window.location.reload();
            });
        } else {
          await lockingContract
            .setUsername(defaultUsername, ownerID, {
              gasLimit: 1000000,
            })
            .then((tx) => {
              tx.wait()
                .then((tx) => {
                  alert("Successfully set username!");
                  window.location.reload();
                })
                .catch((err) => {
                  alert("Error setting username");
                });
            })
            .catch((err) => {
              alert("Something went wrong!");
              window.location.reload();
            });
        }
      } else {
        alert("Please enter a different username");
        setDefaultUsername("");
      }
    } else {
      alert("Please enter a valid username, only letters are allowed");
      setDefaultUsername("");
    }
  };

  const handleChange = (e) => {
    // find e.targer.value in usernameDatabase if it exists
    const username = usernameDatabase.find(
      (username) => username.username === e.target.value
    );

    if (username) {
      setUsernameError("Username already exists!");
      setHasUsernameError(true);
    }
    if (!username) {
      setUsernameError("");
      setHasUsernameError(false);
    }
    setDefaultUsername(e.target.value);
  };

  const [data, setData] = useState({
    name: "",
    number: "",
    email: "",

    discUsername: "",
    favBPOS: "",
    twtUsername: "",

    region: "",
    street: "",
    postalCode: "",
  });

  const [isAnonymous, setIsAnonymous] = useState(false);

  const {
    name,
    number,
    email,

    discUsername,
    favBPOS,
    twtUsername,

    region,
    street,
    postalCode,
  } = data;

  const toggleAnonymous = () => {
    setIsAnonymous(!isAnonymous);
  };

  const [hasNameError, setHasNameError] = useState(false);
  const [hasNumberError, setHasNumberError] = useState(false);
  const [hasEmailError, setHasEmailError] = useState(false);
  const [hasDiscUsernameError, setHasDiscUsernameError] = useState(false);
  const [hasFavBPOSError, setHasFavBPOSError] = useState(false);
  const [hasTwtUsernameError, setHasTwtUsernameError] = useState(false);
  const [hasRegionError, setHasRegionError] = useState(false);
  const [hasStreetError, setHasStreetError] = useState(false);
  const [hasPostalCodeError, setHasPostalCodeError] = useState(false);

  const [isConfirming, setIsConfirming] = useState(false);
  const [isConfirmedText, setIsConfirmedText] = useState("");

  const formHandleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    // check if all required fields are filled
    if (isAgreeing) {
      if (
        name.length > 0 &&
        number.length > 0 &&
        email.length > 0 &&
        discUsername.length > 0 &&
        favBPOS.length > 0 &&
        twtUsername.length > 0 &&
        region.length > 0 &&
        street.length > 0 &&
        postalCode.length > 0
      ) {
        setIsConfirming(true);
        let date = new Date();
        let tempPioneers = stakedPioneerNFT.map((pioneer) => pioneer.tokenId);
        let tempBored = stakedBoredNFT.map((bored) => bored.tokenId);

        emailjs.sendForm(
          config.REACT_APP_EMAIL_SERVICE,
          config.REACT_APP_EMAIL_TEMPLATE,
          e.target,
          config.REACT_APP_MAIL_API_KEY
        );

        await lockingContract
          .claim(ownerID, {
            gasLimit: 1000000,
          })
          .then((tx) => {
            setIsConfirmedText("Confirming your claim...");

            fetch(config.CLAIM, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify([
                {
                  name: name,
                  number: number,
                  email: email,

                  discord: discUsername,
                  bpos: favBPOS,
                  twitter: twtUsername,

                  region: region,
                  street: street,
                  postal: postalCode,

                  anon: isAnonymous,
                  date: date.toLocaleString(),
                  hash: tx.hash,
                  tokenId: ownerID,
                  tier: tier(ownerPoints),
                  status: status(),
                  score: ownerPoints,
                  pioneers: tempPioneers.toString(),
                  bored: tempBored.toString(),
                  username: usernameText,
                },
              ]),
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.message === "Successfully Inserted") {
                  console.log(data.message);
                }
              })
              .catch((err) => {
                alert(err.message);
                window.location.reload();
              });
            tx.wait().then(() => {
              alert("Successfully claimed!");
              window.location.reload();
            });
          })
          .catch((err) => {
            alert(err.message);
            window.location.reload();
          });
      } else {
        alert("Please fill out all required fields");
        setHasNameError(true);
        setHasNumberError(true);
        setHasEmailError(true);
        setHasDiscUsernameError(true);
        setHasFavBPOSError(true);
        setHasTwtUsernameError(true);
        setHasRegionError(true);
        setHasStreetError(true);
        setHasPostalCodeError(true);
      }
    } else {
      alert("Please agree to the terms and conditions");
    }
  };

  const status = () => {
    if (stakedBoredNFT.length <= 0) {
      if (stakedPioneerNFT.length > 0) {
        return "Pioneer";
      }
      return "None";
    }

    if (stakedBoredNFT.length > 0 && stakedBoredNFT.length < 5) {
      if (stakedPioneerNFT.length > 0) {
        return "Holder x Pioneer";
      }
      return "Holder";
    }

    if (stakedBoredNFT.length >= 5 && stakedBoredNFT.length < 10) {
      if (stakedPioneerNFT.length > 0) {
        return "Premier x Pioneer";
      }
      return "Premier";
    }

    if (stakedBoredNFT.length >= 10 && stakedBoredNFT.length < 20) {
      if (stakedPioneerNFT.length > 0) {
        return "Elite x Pioneer";
      }
      return "Elite";
    }

    if (stakedBoredNFT.length >= 20) {
      if (stakedPioneerNFT.length > 0) {
        return "Monarch x Pioneer";
      }
      return "Monarch";
    }
  };

  const tier = (points) => {
    if (points === 0) {
      return "No Tier";
    }
    if (points > 0 && points < 5) {
      return "Azure";
    }
    if (points >= 5 && points < 10) {
      return "Ruby";
    }
    if (points >= 10 && points < 20) {
      return "Gold";
    }
    if (points >= 20) {
      return "Amethyst";
    }
  };

  useEffect(() => {
    if (window.ethereum !== undefined) {
      updateEthers();
    }
  }, []);

  if (tempTime > 0) {
    setTimeout(() => {
      const dateNow = Date.now() / 1000;
      const lamaw = tempTime - dateNow;

      setFinalTime(lamaw);
    }, 60000);
  }

  const [whatId, setWhatId] = useState(0);

  const toggleRedeemForm = () => {
    window.open(
      "https://campaign.beyondclub.xyz/campaign/43b05d0b-a3f8-4815-8bb8-049ff07f6e15?fbclid=IwAR30aLf6HeZUW-y2LP_qoC4qVrHTmYI7WCWrDlPpxLRYTxYT-2TsXLX_nTM"
    );
  };

  const toggleRedeemForm2 = () => {
    // setIsRedeemForm(!isRedeemForm);
    // setWhatId(0);
  };

  const toggleRedeeming = () => {
    setIsRedeeeming(!isRedeeeming);
  };

  return (
    <>
      {isRedeemForm ? (
        <>
          {whatId === 0 ? <RedeemForm address={defaultAccount} /> : null}
          {whatId === 1 ? <RedeemForm2 address={defaultAccount} /> : null}
        </>
      ) : (
        <>
          {isRedeeeming ? (
            <RedeemingComponent
              toggle={toggleRedeemForm}
              toggle2={toggleRedeemForm2}
            />
          ) : (
            <LockingContainer>
              <Navbar />
              {isConfirming ? (
                <LockingWrapper
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <h1>
                    <FiLoader className="rotate" />
                  </h1>
                  <p>{isConfirmedText}</p>
                </LockingWrapper>
              ) : (
                <>
                  {isClaiming ? (
                    <LockingWrapper
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "800px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Bored Exclusive Card</p>
                        <h1
                          style={{
                            marginTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          {ownerPoints === 0 ? "No Tier" : null}
                          {ownerPoints > 0 && ownerPoints < 5
                            ? "Tier 1 - Azure"
                            : null}
                          {ownerPoints >= 5 && ownerPoints < 10
                            ? "Tier 2 - Ruby"
                            : null}
                          {ownerPoints >= 10 && ownerPoints < 20
                            ? "Tier 3 - Gold"
                            : null}
                          {ownerPoints >= 20 ? "Tier 4 - Amethyst" : null}
                        </h1>
                      </div>
                      <form
                        onSubmit={handleSubmitForm}
                        autoComplete="off"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          marginTop: "40px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            gap: "50px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "start",
                              width: "100%",
                              height: "200px",
                            }}
                          >
                            <h3>Contact</h3>
                            <StyledInput
                              type="text"
                              name="name"
                              placeholder={
                                !hasNameError
                                  ? "Full Name"
                                  : "Please enter your full name"
                              }
                              value={name}
                              onChange={formHandleChange}
                            />
                            <StyledInput
                              type="number"
                              name="number"
                              placeholder={
                                !hasNumberError
                                  ? "Phone Number"
                                  : "Please enter your phone number"
                              }
                              value={number}
                              onChange={formHandleChange}
                            />
                            <StyledInput
                              type="email"
                              name="email"
                              placeholder={
                                !hasEmailError
                                  ? "Email"
                                  : "Please enter your email"
                              }
                              value={email}
                              onChange={formHandleChange}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "start",
                              width: "100%",
                              height: "200px",
                            }}
                          >
                            <h3>Your Card Details</h3>
                            <StyledInput
                              type="text"
                              name="discUsername"
                              placeholder={
                                !hasDiscUsernameError
                                  ? "Discord Username and Tag (ex. username#1234)"
                                  : "Please enter your discord username"
                              }
                              value={discUsername}
                              onChange={formHandleChange}
                            />
                            <StyledInput
                              type="text"
                              name="favBPOS"
                              placeholder={
                                !hasFavBPOSError
                                  ? "Favorite BPOS (ex. 1, select one)"
                                  : "Please enter your favorite BPOS"
                              }
                              value={favBPOS}
                              onChange={formHandleChange}
                            />
                            <StyledInput
                              type="text"
                              name="twtUsername"
                              placeholder={
                                !hasTwtUsernameError
                                  ? "Twitter Username (ex. username)"
                                  : "Please enter your twitter username"
                              }
                              value={twtUsername}
                              onChange={formHandleChange}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                                margin: "10px",
                                gap: "10px",
                              }}
                            >
                              {isAnonymous ? (
                                <StyledCheckbox
                                  size={20}
                                  color="white"
                                  onClick={toggleAnonymous}
                                />
                              ) : (
                                <StyledSquare
                                  size={20}
                                  color="white"
                                  onClick={toggleAnonymous}
                                />
                              )}
                              <p>I prefer an anonymous card</p>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <h3>Address</h3>
                          <StyledInput
                            type="text"
                            name="region"
                            placeholder={
                              !hasRegionError
                                ? "Region, Province, City, Barangay"
                                : "Please enter your Region, Province, City, Barangay"
                            }
                            value={region}
                            onChange={formHandleChange}
                          />

                          <StyledInput
                            type="text"
                            name="street"
                            placeholder={
                              !hasStreetError
                                ? "Street Name, Building Name, House Number"
                                : "Please enter your Street Name, Building Name, House Number"
                            }
                            value={street}
                            onChange={formHandleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <StyledInput
                              type="number"
                              name="postalCode"
                              placeholder={
                                !hasPostalCodeError
                                  ? "Postal Code"
                                  : "Please enter your Postal Code"
                              }
                              value={postalCode}
                              onChange={formHandleChange}
                            />
                            <RoundButton
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              Submit
                            </RoundButton>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            alignItems: "center",
                            marginTop: "20px",
                            gap: "10px",
                          }}
                        >
                          {isAgreeing ? (
                            <StyledCheckbox
                              size={30}
                              color="white"
                              onClick={toggleAgreeing}
                            />
                          ) : (
                            <StyledSquare
                              size={30}
                              color="white"
                              onClick={toggleAgreeing}
                            />
                          )}
                          <p
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            I understand that unlocking at least one Bored Punk
                            NFT right after submitting a claim request will
                            automatically void my request. I must wait until my
                            bored exclusive card is delivered right to my
                            doorstep before unlocking.
                          </p>
                        </div>
                        <p
                          style={{
                            marginTop: "50px",
                            textAlign: "center",
                            fontSize: "14px",
                          }}
                        >
                          bored exclusive cards may take up to 3 business weeks
                          for processing and delivery depending on demand
                        </p>
                      </form>
                    </LockingWrapper>
                  ) : (
                    <>
                      {!isConnected ? (
                        <LockingWrapper
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {isLoading ? (
                            <h1>
                              <FiLoader className="rotate" />
                            </h1>
                          ) : (
                            <>
                              <img
                                src={BPOS}
                                style={{
                                  width: "250px",
                                  height: "200px",
                                  objectFit: "cover",
                                  objectPosition: "top",
                                }}
                                alt="BPOS"
                              />
                              <RoundButton onClick={connect}>
                                Connect
                              </RoundButton>
                            </>
                          )}
                        </LockingWrapper>
                      ) : (
                        <>
                          {isApproved ? (
                            <>
                              {!hasUsername ? (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      marginTop: "100px",
                                      gap: "5px",
                                    }}
                                  >
                                    {ownerID > 0 ? (
                                      <>
                                        <h1
                                          style={{
                                            fontSize: "50px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {usernameText + ".bored"}
                                        </h1>
                                        <FiEdit3
                                          style={{
                                            fontSize: "20px",
                                            color: "white",
                                          }}
                                          onClick={() => setHasUsername(true)}
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                  <Link to={`/bored/${ownerID}`}>
                                    <RoundButton2
                                      style={{
                                        fontSize: "12px",
                                        maxWidth: "150px",
                                      }}
                                    >
                                      My Profile
                                    </RoundButton2>
                                  </Link>
                                  <TierContainer>
                                    <h1
                                      style={{
                                        marginTop: "40px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {ownerPoints === 0 ? "No Tier" : null}
                                      {ownerPoints > 0 && ownerPoints < 5
                                        ? "Tier 1 - Azure"
                                        : null}
                                      {ownerPoints >= 5 && ownerPoints < 10
                                        ? "Tier 2 - Ruby"
                                        : null}
                                      {ownerPoints >= 10 && ownerPoints < 20
                                        ? "Tier 3 - Gold"
                                        : null}
                                      {ownerPoints >= 20
                                        ? "Tier 4 - Amethyst"
                                        : null}
                                    </h1>
                                    <TierStats
                                      style={{
                                        marginTop: "20px",
                                      }}
                                    >
                                      <TierData
                                        style={{
                                          borderRight: "2px solid white",
                                        }}
                                      >
                                        <p>BPOS Locked</p>
                                        <h2>{stakedBoredNFT.length}</h2>
                                        <a
                                          style={{
                                            marginTop: "10px",
                                          }}
                                          href="https://tinyurl.com/boredweb3"
                                        >
                                          About Web3
                                        </a>
                                      </TierData>
                                      <TierData>
                                        <p>Bored Score</p>
                                        <h2>{ownerPoints}</h2>
                                        <a
                                          style={{
                                            marginTop: "10px",
                                          }}
                                          href="https://tinyurl.com/boredexclusivecard"
                                        >
                                          Tier Perks & Benefits
                                        </a>
                                      </TierData>
                                      <TierData
                                        style={{
                                          borderLeft: "2px solid white",
                                        }}
                                      >
                                        <p>Status</p>
                                        <h2
                                          style={{
                                            textAlign: "center",
                                          }}
                                        >
                                          {stakedBoredNFT.length === 0
                                            ? "Bored"
                                            : null}
                                          {stakedBoredNFT.length > 0 &&
                                          stakedBoredNFT.length < 5
                                            ? "Holder"
                                            : null}
                                          {stakedBoredNFT.length >= 5 &&
                                          stakedBoredNFT.length < 10
                                            ? "Premier"
                                            : null}
                                          {stakedBoredNFT.length >= 10 &&
                                          stakedBoredNFT.length < 20
                                            ? "Elite"
                                            : null}
                                          {stakedBoredNFT.length >= 20
                                            ? "Monarch"
                                            : null}
                                        </h2>
                                        <a
                                          style={{
                                            marginTop: "10px",
                                          }}
                                          href="https://tinyurl.com/boredscore-mechanics"
                                        >
                                          Point System
                                        </a>
                                      </TierData>
                                    </TierStats>
                                    {stakedBoredNFT.length +
                                      stakedPioneerNFT.length >
                                    0 ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          flexWrap: "wrap",
                                          width: "100%",
                                          marginTop: "20px",
                                          gap: "10px",
                                        }}
                                      >
                                        <RoundButton2 onClick={toggleRedeeming}>
                                          Redeem
                                        </RoundButton2>
                                        {finalTime <= 0 ? (
                                          <>
                                            {!isClaimable ? (
                                              <RoundButton
                                                onClick={() =>
                                                  setIsClaiming(true)
                                                }
                                              >
                                                Claim
                                              </RoundButton>
                                            ) : null}
                                          </>
                                        ) : null}
                                      </div>
                                    ) : null}
                                  </TierContainer>

                                  <LockingWrapper
                                    style={{
                                      gap: "50px",
                                    }}
                                  >
                                    {totalStaked > 0 ? (
                                      <Staked>
                                        <h1>Locked</h1>
                                        {finalTime > 0 ? (
                                          <p>
                                            Unlockable in :{" "}
                                            {Math.floor(
                                              finalTime / (60 * 60 * 24)
                                            ) +
                                              " days " +
                                              Math.floor(
                                                (finalTime % (60 * 60 * 24)) /
                                                  (60 * 60)
                                              ) +
                                              " hours " +
                                              Math.floor(
                                                (finalTime % (60 * 60)) / 60
                                              ) +
                                              " minutes "}
                                          </p>
                                        ) : null}
                                        <TokenContainer
                                          style={{
                                            marginTop: "30px",
                                          }}
                                        >
                                          {stakedPioneerNFT.map(
                                            (token, index) => (
                                              <NFTCard3
                                                tokenId={token.tokenId}
                                                selected={token.selected}
                                                staked={token.staked}
                                                index={index}
                                                finalTime={finalTime}
                                              />
                                            )
                                          )}
                                          {stakedBoredNFT.map(
                                            (token, index) => (
                                              <NFTCard4
                                                tokenId={token.tokenId}
                                                selected={token.selected}
                                                staked={token.staked}
                                                index={index}
                                                finalTime={finalTime}
                                              />
                                            )
                                          )}
                                        </TokenContainer>
                                        {finalTime <= 0 ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              gap: "10px",
                                            }}
                                          >
                                            <RoundButton
                                              onClick={unstakeAll}
                                              style={{
                                                marginTop: "20px",
                                              }}
                                            >
                                              Unlock
                                            </RoundButton>
                                            <RoundButton2
                                              onClick={selectAllStakedNFT}
                                              style={{
                                                marginTop: "20px",
                                              }}
                                            >
                                              Select All
                                            </RoundButton2>
                                          </div>
                                        ) : null}
                                      </Staked>
                                    ) : null}
                                    {totalUnstaked > 0 ? (
                                      <Unstaked>
                                        <h1>Unlocked</h1>
                                        <TokenContainer>
                                          {unstakedPioneerNFT.map(
                                            (token, index) => (
                                              <NFTCard
                                                tokenId={token.tokenId}
                                                selected={token.selected}
                                                staked={token.staked}
                                                index={index}
                                              />
                                            )
                                          )}
                                          {unstakedBoredNFT.map(
                                            (token, index) => (
                                              <NFTCard2
                                                tokenId={token.tokenId}
                                                selected={token.selected}
                                                staked={token.staked}
                                                index={index}
                                              />
                                            )
                                          )}
                                        </TokenContainer>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "10px",
                                          }}
                                        >
                                          <RoundButton onClick={stakeAll}>
                                            Lock
                                          </RoundButton>
                                          <RoundButton2
                                            onClick={selectAllUnstakedNFT}
                                          >
                                            Select All
                                          </RoundButton2>
                                        </div>
                                      </Unstaked>
                                    ) : null}
                                  </LockingWrapper>
                                </>
                              ) : (
                                <LockingWrapper>
                                  <form
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "20px",
                                    }}
                                    onSubmit={handleSubmit}
                                  >
                                    <input
                                      type="text"
                                      placeholder="Username"
                                      value={defaultUsername}
                                      onChange={handleChange}
                                      style={{
                                        width: "200px",
                                        height: "40px",
                                        borderRadius: "5px",
                                        border: "none",
                                        backgroundColor: "rgba(0,0,0,0.4)",
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    />
                                    {usernameError.length > 0 ? (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {usernameError}
                                      </p>
                                    ) : null}
                                    {!hasUsernameError ? (
                                      <RoundButton>Submit</RoundButton>
                                    ) : null}
                                  </form>
                                </LockingWrapper>
                              )}
                              <Footer />
                            </>
                          ) : (
                            <>
                              {isLoading ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "20px",
                                  }}
                                >
                                  <h1>
                                    <FiLoader className="rotate" />
                                  </h1>
                                  <p>{loadingText}</p>
                                </div>
                              ) : (
                                <LockingWrapper
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "20px",
                                  }}
                                >
                                  <p>
                                    You need to approve your nfts to enable
                                    locking.
                                  </p>
                                  <RoundButton onClick={approve}>
                                    Approve
                                  </RoundButton>
                                </LockingWrapper>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </LockingContainer>
          )}
        </>
      )}
    </>
  );
};

export default Locking;
