import React from "react";

const UpdatesBlock = ({ src, title, link, date }) => {
  // convert date to readable format
  const dateFormat = new Date(date);
  const dateString = dateFormat.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "max-content",
      }}
    >
      <img
        style={{
          width: "500px",
          height: "200px",
          objectFit: "cover",
        }}
        src={src}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "500px",
          height: "200px",
          padding: "40px",
          backgroundColor: "white",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <h2
            style={{
              color: "black",
            }}
          >
            {title}
          </h2>
          <p
            style={{
              color: "black",
            }}
          >
            {dateString}
          </p>
        </div>
        <a
          href={link}
          style={{
            color: "brown",
            textDecoration: "none",
          }}
        >
          Read More
        </a>
      </div>
    </div>
  );
};

export default UpdatesBlock;
