import React from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import styled from "styled-components";

const ProfileImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

const CommentFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  padding: 20px;
`;

const StyledInput = styled.input`
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 40px;
  border-radius: 50px;
  padding: 0 20px;
  border: none;
  outline: none;
  ::placeholder {
    color: #fff;
  }
`;

const Splitter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #31b07c;
  color: #fff;
  border-radius: 50px;
  padding: 5px 10px;
  border: none;
`;

const StyledAdd = styled(AiFillPlusCircle)`
  color: rgba(255, 255, 255, 0.2);
  font-size: 20px;
`;

const StyledSelect = styled.select`
  background-color: rgba(199, 125, 187, 0.8);
  color: #fff;
  border-radius: 50px;
  padding: 5px 10px;
`;

const CommentForm = () => {
  return (
    <CommentFormContainer>
      <ProfileImage src="https://i.imgur.com/0y0tjYX.png" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "10px",
        }}
      >
        <Splitter
          style={{
            gap: "10px",
          }}
        >
          <StyledInput placeholder="Write a comment..." />
          <StyledButton>Post</StyledButton>
        </Splitter>
        <Splitter
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <StyledButton
            style={{
              backgroundColor: "rgba(199, 125, 187, 0.2)",
            }}
          >
            Tags
            <StyledAdd />
          </StyledButton>
          <StyledSelect>
            <option value="public">Public</option>
            <option value="private">Private</option>
          </StyledSelect>
        </Splitter>
      </div>
    </CommentFormContainer>
  );
};

export default CommentForm;
