import { FiAward, FiHash, FiHeart, FiLoader, FiUser } from "react-icons/fi";

import { BiTrophy } from "react-icons/bi";
import { BsSafe } from "react-icons/bs";
import Elite from "../assets/images/Elite.png";
import Holder from "../assets/images/Holder.png";
import { Link } from "react-router-dom";
import Monarch from "../assets/images/Monarch.png";
import None from "../assets/images/None.png";
import Pioneer from "../assets/images/Pioneer.png";
import Premier from "../assets/images/Premier.png";
import React from "react";
import Tippy from "@tippyjs/react";
import styled from "styled-components";

const ProfileSplitter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const LeaderboardCard = ({
  username,
  score,
  lockedTokens,
  status,
  index,
  likes,
  tokenId,
  favPFP,
}) => {
  const cleanedId = (uncleaned) => {
    if (uncleaned.includes("pioneer")) {
      let num = uncleaned.replace("pioneer:", "");
      const suffix = ".png";
      // remove spaces
      num = num.replace(/\s/g, "");
      return `https://bpos-db.herokuapp.com/pioneer/${num}${suffix}`;
    } else if (uncleaned.includes("bored")) {
      let num = uncleaned.replace("bored:", "");
      num = num.replace(/\s/g, "");
      return num === "0"
        ? `https://bpos-db.herokuapp.com/pioneer/${num}.png`
        : `https://media.raritysniper.com/bored-punks-of-society/${num}-600.webp?cacheId=3`;
    }
  };

  const src = () => {
    if (status.includes("Pioneer")) {
      return Pioneer;
    }
    if (status === "Holder") {
      return Holder;
    }
    if (status === "Premier") {
      return Premier;
    }
    if (status === "Elite") {
      return Elite;
    }
    if (status === "Monarch") {
      return Monarch;
    }
    if (status === "None") {
      return None;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "18%",
      }}
    >
      <Link
        to={`/bored/${tokenId}`}
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <img
          src={cleanedId(favPFP)}
          alt="profile"
          style={{
            width: "100%",
            height: "100%",
            objectPosition: "center",
            objectFit: "cover",
            borderRadius: "10px 10px 0 0",
          }}
        />
      </Link>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          padding: "10px",
          borderRadius: "0 0 10px 10px",
          gap: "10px",
        }}
      >
        <ProfileSplitter
          style={{
            flexDirection: "column",
            paddingBottom: "5px",
            width: "100%",
            gap: "10px",
          }}
        >
          <img
            src={src()}
            alt="profile"
            style={{
              width: "100%",
              height: "100%",
              objectPosition: "center",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
          <ProfileSplitter
            style={{
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px",
              border: "1px solid #fff",
              borderRadius: "10px",
            }}
          >
            <ProfileSplitter>
              <Tippy
                content={
                  <p
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      backgroundColor: "white",
                      fontSize: "12px",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    Score
                  </p>
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FiAward
                    size={20}
                    style={{ color: "white", cursor: "help" }}
                  />
                  <h2
                    style={{
                      cursor: "help",
                    }}
                  >
                    {score}
                  </h2>
                </div>
              </Tippy>

              <Tippy
                content={
                  <p
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      backgroundColor: "white",
                      fontSize: "12px",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    Rank
                  </p>
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BiTrophy
                    size={20}
                    style={{ color: "white", cursor: "help" }}
                  />
                  <h2
                    style={{
                      cursor: "help",
                    }}
                  >
                    {index + 1}
                  </h2>
                </div>
              </Tippy>
            </ProfileSplitter>
            <ProfileSplitter>
              <Tippy
                content={
                  <p
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      backgroundColor: "white",
                      fontSize: "12px",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    Locked Tokens
                  </p>
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BsSafe
                    size={20}
                    style={{
                      color: "white",
                      cursor: "help",
                    }}
                  />
                  <h2
                    style={{
                      cursor: "help",
                    }}
                  >
                    {lockedTokens}
                  </h2>
                </div>
              </Tippy>

              <Tippy
                content={
                  <p
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      backgroundColor: "white",
                      fontSize: "12px",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    Likes
                  </p>
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FiHeart
                    size={20}
                    style={{
                      color: "white",
                      cursor: "help",
                    }}
                  />
                  <h2
                    style={{
                      cursor: "help",
                    }}
                  >
                    {likes}
                  </h2>
                </div>
              </Tippy>
            </ProfileSplitter>
          </ProfileSplitter>
        </ProfileSplitter>
        <Link to={`/bored/${tokenId}`}>
          <p
            style={{
              width: "100%",
              textAlign: "right",
            }}
          >
            {username}.bored
          </p>
        </Link>
      </div>
    </div>
  );
};

export default LeaderboardCard;
