import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";
import BTNBG from "../assets/gif/BTNBG.gif";
import SB1 from "../assets/images/SB1.jpg";
import SB2 from "../assets/images/SB2.jpg";
import SB3 from "../assets/images/SB3.jpg";
import SB4 from "../assets/images/SB4.jpg";
import config from "../config/config.json";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  gap: 20px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: max-content;
  gap: 10px;
`;

const FormLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  gap: 20px;
`;

const StyledImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 10px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 410px;
  gap: 10px;
`;

const StyledParagraph = styled.p`
  font-size: 13px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const RoundButton = styled.button`
  background-image: url(${BTNBG});
  background-repeat: no-repeat;
  background-size: cover;
  width: 200px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50px;
  color: white;
`;

const StyledInput = styled.input`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  color: white;
  background-color: transparent;
  min-width: 350px;
  border-bottom: 1px solid white;
  :focus {
    outline: none;
  }
  ::-webkit-input-placeholder {
    color: white;
  }
`;

const RedeemForm = ({ address }) => {
  const [email, setEmail] = useState("");

  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    // check if email is empty
    if (email === "") {
      alert("Please enter your email");
      return;
    }
    // check if email is valid
    if (!email.includes("@")) {
      alert("Please enter a valid email");
      return;
    }
    try {
      const response = await axios.post(config.SB_API_URL + config.SB_API_KEY, [
        {
          email: email,
          wallet: address,
        },
      ]);
      if (response.data.message === "Successfully Inserted") {
        alert("Successfully Submitted");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormContainer>
      <FormLayout>
        <FormWrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "400px",
              gap: "10px",
            }}
          >
            <h2>Larry Alacala's Slice of Life NFT</h2>
            <StyledParagraph>
              What is Scarletbox? Scarletbox is a Blue Chip Arts NFT Launchpad.
              Its mission is to bridge the generation through arts and culture
              by featuring blue chip works of National Artists in its drops.
            </StyledParagraph>
            <StyledParagraph>
              Larry Alcala Slice of Life NFT For Scarletbox’s second drop, it
              pays Tribute to the beloved Cartoonist and National Artist Larry
              Alcala with a witty and fun PFP (Profile Picture) Collection.
              Larry Alcala is best known for his weekly Sunday series the Slice
              of Life which became an anticipated issue every Sunday for all
              Filipino families where it's a game to find his hidden profile. He
              is a true symbol of the Pinoy Pop Culture and was also awarded the
              Grand Collar of the Order of National Artists.
            </StyledParagraph>
            <StyledParagraph>
              This tribute celebrates with inspiring NFT projects of today:
              Bored Punks of Society, Planktoons, Distort Monsters, Black Eyed
              Freaks, Mustachioverse by Ownly, and Bjorn Calleja. The images of
              these projects will be embedded in select Larry Alcala PFP to
              create rares.
            </StyledParagraph>
            <StyledParagraph>
              Get your Larry Alacala's Slice of Life NFT Whitelist spot now!
            </StyledParagraph>
          </div>
          <ImageWrapper>
            <StyledImage src={SB1} alt="Scarletbox" />
            <StyledImage src={SB2} alt="Scarletbox" />
            <StyledImage src={SB3} alt="Scarletbox" />
            <StyledImage src={SB4} alt="Scarletbox" />
          </ImageWrapper>
        </FormWrapper>
        <InputWrapper>
          <StyledParagraph>
            <b>Whitelist giveaway has already ended!</b>
          </StyledParagraph>
          <RoundButton>
            <a href="https://scarletbox.io/">Visit Scarletbox</a>
          </RoundButton>
        </InputWrapper>
      </FormLayout>
    </FormContainer>
  );
};

export default RedeemForm;
