import React from "react";
import styled from "styled-components";
import BPOS from "../assets/images/BPOS.png";
import CompanyForum from "./CompanyForum";
import CompanyHeader from "./CompanyHeader";
import CompanyTeam from "./CompanyTeam";

const RightCompContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 80vw;
  background-color: rgba(0, 0, 0, 0.6);
`;
const HeaderLogo = styled.img`
  width: 80px;
  height: 80px;
`;

const Splitter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
`;

const BPOSNavbar = () => {
  return (
    <Splitter
      style={{
        position: "fixed",
      }}
    >
      <HeaderLogo src={BPOS} />
    </Splitter>
  );
};

const RightCompany = ({
  logo,
  banner,
  name,
  isVerified,
  description,
  website,
  socials,
  blockchain,
  tags,
  team,
}) => {
  return (
    <RightCompContainer>
      <BPOSNavbar />
      <CompanyHeader
        logo={logo}
        name={name}
        isVerified={isVerified}
        description={description}
        website={website}
        socials={socials}
        blockchain={blockchain}
        tags={tags}
        banner={banner}
      />
      <CompanyTeam name={name} team={team} />
      <CompanyForum />
    </RightCompContainer>
  );
};

export default RightCompany;
