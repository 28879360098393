import React, { useState } from "react";
import {
  FaDiscord,
  FaFacebook,
  FaInstagram,
  FaSpotify,
  FaTwitter,
} from "react-icons/fa";
import { Link, LInk } from "react-router-dom";
import styled from "styled-components";
import BTNBG from "../assets/gif/BTNBG.gif";
import b1 from "../assets/images/b1.png";
import BPOS from "../assets/images/BPOS.png";
import Navbar from "../components/Navbar";
import Footer from "./Footer";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  color: white;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${b1});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 200px;
`;

const HomeButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50px;
  background-image: url(${BTNBG});
  background-repeat: no-repeat;
  background-size: cover;
  width: 200px;
  height: 40px;
  padding: 10px;
  color: white;
  font-size: 15px;
  :hover {
    cursor: pointer;
  }
`;

const HomeButton2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  border-radius: 50px;
  width: 200px;
  height: 40px;
  padding: 10px;
  color: white;
  background-color: transparent;
  font-size: 15px;
  :hover {
    cursor: pointer;
  }
`;

const HomeLogo = styled.img`
  width: 400px;
  height: 310px;
  object-fit: cover;
  object-position: top;
`;

const Home = () => {
  const opensea = () => {
    window.open("https://www.boredpunksociety.com/boredcollection");
  };

  return (
    <>
      <Navbar />
      <HomeContainer>
        <HomeWrapper>
          <HomeLogo src={BPOS} />
        </HomeWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <HomeButton to="/myboredpunk">My Bored Punk</HomeButton>
          <HomeButton2 onClick={opensea}>Get a Bored Punk</HomeButton2>
        </div>
      </HomeContainer>
      <Footer />
    </>
  );
};

export default Home;
