import React, { useEffect, useState } from "react";
import styled from "styled-components";
import medium from "../assets/images/medium.jpg";
import Navbar from "../components/Navbar";
import UpdatesBlock from "../components/UpdatesBlock";
import Footer from "./Footer";

const UpdatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  gap: 20px;
`;

const FeedContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px;
`;

const UpdatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const Updates = () => {
  const [feed, setFeed] = useState([]);

  useEffect(() => {
    fetch("https://v1.nocodeapi.com/jie/medium/wkpxbAMLnYRGxUNf")
      .then((res) => res.json())
      .then((data) => {
        setFeed(data);
      });
  }, []);

  return (
    <>
      <Navbar />
      <UpdatesContainer>
        <UpdatesWrapper>
          <h1
            style={{
              marginTop: "100px",
            }}
          >
            Latest News
          </h1>
          <FeedContainer>
            {feed.map((item) => (
              <UpdatesBlock
                title={item.title}
                link={item.link}
                date={item.published}
                src={medium}
              />
            ))}
          </FeedContainer>
        </UpdatesWrapper>
      </UpdatesContainer>
      <Footer />
    </>
  );
};

export default Updates;
