import Holder from "../assets/images/Holder.png";
import LeftCompany from "../components/LeftCompany";
import { MdVerified } from "react-icons/md";
import Monarch from "../assets/images/Monarch.png";
import None from "../assets/images/None.png";
import Pioneer from "../assets/images/Pioneer.png";
import Premier from "../assets/images/Premier.png";
import React from "react";
import RightCompany from "../components/RightCompany";
import styled from "styled-components";

const Styledverified = styled(MdVerified)`
 color: #1da1f2;
 font-size: 30px;
`;

const CompanyContainer = styled.div`
 display: flex;
 flex-direction: column;
 min-height: 100vh;
 width: 100vw;
 gap: 20px;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
`;

const FirstContainer = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 width: 60%;
 box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
 border-radius: 20px;
`;

const BannerImage = styled.img`
 width: 100%;
 border-radius: 20px 20px 0 0;
`;

const FirstBottomContainer = styled.div`
 background-color: rgba(0, 0, 0, 0.6);
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 gap: 20px;
 padding: 20px;
 width: 100%;
 height: 150px;
 border-radius: 0px 0px 20px 20px;
 @media (max-width: 1024px) {
  height: 100px;
 }
 @media (max-width: 768px) {
  flex-direction: column;
  height: max-content;
 }
 @media (max-width: 425px) {
  gap: 10px;
 }
`;

const ProfileImage = styled.img`
 position: inherit;
 width: 25%;
 transform: translateY(-35%);
 border-radius: 10px;
 @media (max-width: 768px) {
  transform: translateY(-100%);
 }
`;

const ProfileImageWrapper = styled.div`
 display: none;
 height: 0px;
 width: 100%;
 @media (max-width: 768px) {
  display: block;
 }
`;

const ProfileImage3 = styled(ProfileImage)`
 @media (max-width: 768px) {
  display: none;
 }
`;

const Splitter = styled.div`
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 gap: 20px;
 @media (max-width: 1024px) {
  gap: 10px;
 }
 @media (max-width: 680px) {
  gap: 5px;
 }
`;

const UsernameText2 = styled.h1`
 text-align: center;
 @media (max-width: 1024px) {
  font-size: 20px;
 }
`;

const CompanyDetails = {
 name: "Playdex",
 logo: "",
 isVerified: true,
 description:
  "Playdex is a NFT rental marketplace for play-to-earn games. Play for free, and follow the latest news in web3 gaming.",
 website: "https://playdex.io/",
 blockchain: ["Ethereum", "Polygon", "Solana"],
 socials: {
  facebook: "https://www.facebook.com/playdexio",
  twitter: "https://twitter.com/playdexio",
  discord: "https://discord.gg/2Z8Y4Y4",
  instagram: "https://www.instagram.com/playdexio/",
  linkedin: "https://www.linkedin.com/company/playdexio/",
  medium: "https://playdexio.medium.com/",
  opensea: "https://opensea.io/collection/playdex",
 },
 tags: ["NFT", "Marketplace", "Play-to-Earn"],
 team: [
  {
   name: "Ricardo",
   tokenId: "1",
   role: "Chief Executive Officer",
  },
  {
   name: "Eduardo",
   tokenId: "2",
   role: "Chief Technology Officer",
  },
  {
   name: "Rafael",
   tokenId: "3",
   role: "Chief Marketing Officer",
  },
  {
   name: "Miguel",
   tokenId: "4",
   role: "Chief Financial Officer",
  },
  {
   name: "Daniel",
   tokenId: "5",
   role: "Chief Operating Officer",
  },
 ],
 likes: [
  {
   tokenID: 1,
  },
  {
   tokenID: 2,
  },
  {
   tokenID: 3,
  },
  {
   tokenID: 4,
  },
 ],
 followers: [
  {
   tokenID: 1,
  },
  {
   tokenID: 2,
  },
  {
   tokenID: 3,
  },
  {
   tokenID: 4,
  },
 ],
 viewers: 896248,
 visitors: 12367889,
 updates: [
  {
   title: "Playdex is now on Polygon",
   date: "2021-09-01",
   description:
    "Playdex is now on Polygon, the second largest blockchain network in the world. We are excited to bring Playdex to the Polygon community and to continue our mission of bringing play-to-earn games to the masses.",
   likes: 123,
   comments: 123,
   link: "https://playdex.io/",
  },
 ],
 discussions: [
  {
   username: "Ricardo",
   tokenId: 1,
   timestamp: Date.now(),
   message:
    "Playdex is now on Polygon, the second largest blockchain network in the world. We are excited to bring Playdex to the Polygon community and to continue our mission of bringing play-to-earn games to the masses.",
   upvotes: ["Ricardo"],
   downvotes: ["Miguel"],
   replies: [
    {
     username: "Miguel",
     tokenId: 2,
     timestamp: Date.now(),
     message:
      "Playdex is now on Polygon, the second largest blockchain network in the world. We are excited to bring Playdex to the Polygon community and to continue our mission of bringing play-to-earn games to the masses.",
     upvotes: ["Ricardo"],
     downvotes: ["Miguel"],
    },
   ],
  },
 ],
};

const CompanyProfile = () => {
 const src = () => {
  return Monarch;
 };
 return (
  <CompanyContainer>
   {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <RightCompany
          logo={CompanyDetails.logo}
          name={CompanyDetails.name}
          isVerified={CompanyDetails.isVerified}
          description={CompanyDetails.description}
          website={CompanyDetails.website}
          socials={CompanyDetails.socials}
          blockchain={CompanyDetails.blockchain}
          tags={CompanyDetails.tags}
          team={CompanyDetails.team}
          banner={CompanyDetails.banner}
        />
        <LeftCompany />
      </div> */}

   <FirstContainer>
    <BannerImage src={src()} alt="banner" />
    <FirstBottomContainer>
     <UsernameText2
      style={{
       display: "flex",
       alignItems: "center",
       gap: "10px",
      }}
     >
      <Styledverified /> Bored Partner
     </UsernameText2>
    </FirstBottomContainer>
   </FirstContainer>
  </CompanyContainer>
 );
};

export default CompanyProfile;
