import React from "react";
import { AiFillLike } from "react-icons/ai";
import {
  FaDiscord,
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaMedium,
  FaTwitterSquare,
} from "react-icons/fa";
import { FiGlobe } from "react-icons/fi";
import { MdVerified } from "react-icons/md";
import styled from "styled-components";
import BTNBG from "../assets/gif/BTNBG.gif";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 100%;
  gap: 20px;
  padding: 100px 20px 20px 20px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${(props) => props.banner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const CompanyLogo = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 10px;
`;

const Splitter = styled.div`
  display: flex;
  flex-direction: row;
`;

const CompanyName = styled.h2``;
const CompanyDescription = styled.p`
  max-width: 800px;
`;
const CompanyPartner = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  border-left: 1px solid #fff;
  font-weight: 400;
`;

const Styledverified = styled(MdVerified)`
  color: #1da1f2;
  font-size: 20px;
`;

const Liked = styled(AiFillLike)`
  color: #1da1f2;
  font-size: 20px;
`;

const NotLiked = styled(AiFillLike)`
  color: lightgray;
  font-size: 20px;
`;

const StyledGlobe = styled(FiGlobe)`
  color: lightgray;
  font-size: 20px;
`;

const StyledTwitter = styled(FaTwitterSquare)`
  color: lightgray;
  font-size: 20px;
`;

const StyledFacebook = styled(FaFacebookSquare)`
  color: lightgray;
  font-size: 20px;
`;

const StyledInstagram = styled(FaInstagramSquare)`
  color: lightgray;
  font-size: 20px;
`;

const StyledLinkedin = styled(FaLinkedin)`
  color: lightgray;
  font-size: 20px;
`;

const StyledMedium = styled(FaMedium)`
  color: lightgray;
  font-size: 20px;
`;

const StyledDiscord = styled(FaDiscord)`
  color: lightgray;
  font-size: 20px;
`;

const StyledLink = styled.a`
  color: lightgray;
  text-decoration: none;
`;

const StyledTags = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px 15px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  font-size: 14px;
`;

const RoundButton = styled.button`
  background-image: url(${BTNBG});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50px;
  color: white;
  height: 40px;
`;

const CompanyHeader = ({
  logo,
  banner,
  name,
  isVerified,
  description,
  website,
  socials,
  blockchain,
  tags,
}) => {
  return (
    <HeaderContainer banner={banner}>
      <Splitter
        style={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Splitter
            style={
              {
                // logo, name, partner and description
              }
            }
          >
            <Splitter
              style={{
                gap: "20px",
              }}
            >
              <CompanyLogo src={logo} />
              <Splitter>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "space-evenly",
                    width: "100%",
                  }}
                >
                  <Splitter
                    style={{
                      gap: "10px",
                    }}
                  >
                    <Splitter
                      style={{
                        // company name and erification
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <CompanyName>{name}</CompanyName>

                      <CompanyPartner>
                        {isVerified ? <Styledverified /> : null} Bored Partner
                      </CompanyPartner>
                    </Splitter>

                    <Splitter
                      style={{
                        // like and follow
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <NotLiked />
                      <RoundButton>
                        <b>Following</b>
                      </RoundButton>
                    </Splitter>
                  </Splitter>
                  <CompanyDescription>{description}</CompanyDescription>
                </div>
              </Splitter>
            </Splitter>
          </Splitter>
          <Splitter
            style={{
              // social media and website links
              alignItems: "center",
              gap: "100px",
            }}
          >
            <div
              style={{
                // company links

                gap: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Splitter
                style={{
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <StyledGlobe />
                <StyledLink href={website}>
                  {website.replace("https://", "").replace("/", "")}
                </StyledLink>
              </Splitter>
              <Splitter
                style={{
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <StyledLink href={socials.facebook}>
                  <StyledFacebook />
                </StyledLink>
                <StyledLink href={socials.twitter}>
                  <StyledTwitter />
                </StyledLink>
                <StyledLink href={socials.instagram}>
                  <StyledInstagram />
                </StyledLink>
                <StyledLink href={socials.discord}>
                  <StyledDiscord />
                </StyledLink>
                <StyledLink href={socials.linkedin}>
                  <StyledLinkedin />
                </StyledLink>
                <StyledLink href={socials.medium}>
                  <StyledMedium />
                </StyledLink>
              </Splitter>
            </div>

            <div
              style={{
                // company blockchain and tags

                gap: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <Splitter>
                <p>
                  Blockchain:{" "}
                  <b>
                    {blockchain.map((chain) => (
                      <span>
                        {chain}
                        {chain !== blockchain[blockchain.length - 1]
                          ? ", "
                          : ""}
                      </span>
                    ))}
                  </b>
                </p>
              </Splitter>
              <Splitter
                style={{
                  gap: "10px",
                }}
              >
                <p>Tags:</p>
                {tags.map((tag) => (
                  <StyledTags>{tag}</StyledTags>
                ))}
              </Splitter>
            </div>
          </Splitter>
        </div>
        <Splitter>
          <CompanyLogo
            style={{
              width: "250px",
              height: "250px",
            }}
            src="https://i.imgur.com/4Q4Q4Q4.png"
          />
        </Splitter>
      </Splitter>
    </HeaderContainer>
  );
};

export default CompanyHeader;
