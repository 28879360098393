import React, { useState } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import styled from "styled-components";

const RoundButton2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  border-radius: 50px;
  width: 50px;
  height: 35px;
  padding: 10px;
  color: white;
  background-color: transparent;
  :hover {
    cursor: pointer;
  }
`;

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
  const pageNumbers = [];
  const [currentPage, setCurrentPage] = useState(1);

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const nextPage = () => {
    if (currentPage > pageNumbers.length) {
      setCurrentPage(pageNumbers.length);
      paginate(pageNumbers.length);
    } else {
      setCurrentPage(currentPage + 1);
      paginate(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage <= 1) {
      setCurrentPage(1);
      paginate(1);
    } else {
      setCurrentPage(currentPage - 1);
      paginate(currentPage - 1);
    }
  };

  return (
    // <nav
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     width: "200px",
    //     marginTop: "20px",
    //   }}
    // >
    //   <ul
    //     className="pagination"
    //     style={{
    //       display: "flex",
    //       flexDirection: "row",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       gap: "20px",
    //     }}
    //   >
    //     {pageNumbers.map((number) => (
    //       <li
    //         key={number}
    //         className="page-item"
    //         style={{
    //           display: "flex",
    //           flexDirection: "row",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           padding: "10px",
    //           border: "2px solid white",
    //           width: "50px",
    //           height: "50px",
    //         }}
    //         onClick={() => paginate(number)}
    //       >
    //         <a className="page-link">{number}</a>
    //       </li>
    //     ))}
    //   </ul>
    // </nav>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
      }}
    >
      {currentPage > 1 ? (
        <RoundButton2 onClick={prevPage}>
          <FiArrowLeft />
        </RoundButton2>
      ) : null}
      <h2>
        {currentPage} of {pageNumbers.length}
      </h2>
      {currentPage < pageNumbers.length ? (
        <RoundButton2 onClick={nextPage}>
          <FiArrowRight />
        </RoundButton2>
      ) : null}
    </div>
  );
};

export default Pagination;
